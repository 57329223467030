import React from 'react';
import { Link } from 'react-router-dom';

import bg from '../../../../../assets/illustrations/startup-rocket.svg'

import './no-eligible-libraries.scoped.scss';

const NoEligibleLibraries = () => (
  <div className="container flex-column">
    <img className="bg-image" alt="No has hecho ningún test" src={bg} />
    <div className="mb-3 mt-5 text-center">
      <h4>¡No corras tanto!</h4>
      No puedes hacer tests si no tienes una librería con preguntas
    </div>
    <div>
      <Link className="btn btn-primary" to="/librerias">Preparar mis librerías</Link>
    </div>
  </div>
)

export default NoEligibleLibraries;
