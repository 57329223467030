import TLibrary, { TLstats } from '../types/TLibrary';
import { LQuestion } from '../types/TQuestion';
import { TTContent } from '../types/TTest';

export class Library implements TLibrary {
    id = -1;

    questions = [];

    owner = -1;

    name = '';

    creation = new Date();

    stats: TLstats = {
        success: 0,
        fail: 0,
        empty: 0,
        total: 0,
    };

    public static findById = (list: TLibrary[], id: number) => list.find((lib) => lib.id === id)

    public static findIndexById = (list: TLibrary[], id: number) => list.findIndex((lib) => lib.id === id)

    public static findQuestionById = (list: LQuestion[], id: number) => list.find(quest => quest.id === id)

    public static findQuestionIndexById = (list: LQuestion[], id: number) => list.findIndex(quest => quest.id === id || quest.parent?.id === id)
}
