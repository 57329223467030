import React, { useEffect, useState } from 'react';
import api from 'packages/_core/services/api.service';

import './login.scoped.scss';
import {
 Alert, Col, Container, Row,
} from 'react-bootstrap';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { RootState } from 'packages/_core/app.store';
import { useDispatch, useSelector } from 'react-redux';
import { UserDispatch } from 'packages/_core/user/thunk.type';
import { thunkUserLogin } from 'packages/_core/user/thunks/login.thunk';

import Cookies from 'js-cookie';
import { sendLog } from 'packages/_core/services/loki-reporter.service';
import { thunkLoadUser } from 'packages/_core/user/thunks/load.thunk';
import { AxiosError } from 'axios';

enum errors {
  NOTFOUND = 0,
  USERNAME = 1,
  PASSWORD = 2,
}

export const loginPage = () => {
  const [canSubmit, setCanSubmit] = useState(false)
  const [data, setData] = useState({
    user: '',
    pass: '',
  })
  const [error, setError] = useState<errors|null>(null)

  const user = useSelector((state: RootState) => state.user)
  const location = useLocation();
  const dispatch: UserDispatch = useDispatch()

  const login = async (e: any) => {
    e.preventDefault();
    dispatch(thunkUserLogin(data)).catch((e: AxiosError) => {
      console.log('error data', { error: e, resp: e.response })
      if (e.response?.status === 404) {
        // User not found
        setError(errors.NOTFOUND);
      }
    })
  }

  useEffect(() => {
    // If has local cookie but no user data, ask for data directly
    /* if (Cookies.get('opositario') && !user) {
      console.info('HAS A COOKIE! try to retrieve user data')
      dispatch(thunkLoadUser())
    } */
  }, [])

  const updateData = (control: 'user' | 'pass', e: any) => {
    const val = e.target.value;
    if (control === 'user') {
      setData({
        ...data,
        user: val,
      })
      if (val !== '' && data.pass !== '') {
        setCanSubmit(true);
      } else {
        setCanSubmit(false);
      }
    } else {
      setData({
        ...data,
        pass: val,
      })
      if (data.user !== '' && val !== '') {
        setCanSubmit(true);
      } else {
        setCanSubmit(false);
      }
    }
  }

  if (user) { // User signed up
    if (location.state) { // has something to go back?
      const { from } = location.state as { from: string };
      if (from) {
        <Redirect to={from} />
      } else { // No place to come back, just go to home
        <Redirect to="/" />
      }
    } else { // No place to come back, just go to home
      <Redirect to="/" />
    }
  }

  const errorAlertElement = error !== null ? (
    <Row className="justify-content-center">
      <div style={{ width: '100%', maxWidth: '370px' }}>
        <Alert variant="danger">
          Usuario o contraseña incorrectos.
          <br />
          Inténtalo de nuevo
        </Alert>
      </div>
    </Row>
  ) : <></>;

  return user ? <Redirect to="/" /> : (
    <Container className="pt-5">
      {errorAlertElement}
      <Row className="justify-content-center">
        <div className="box">
          <div className="mb-4" id="heading">
            <h3>Opositario</h3>
          </div>
          <form onSubmit={login}>
            <div className="form-group first">
              <input
                placeholder="Usuario o correo electrónico"
                id="username"
                type="text"
                className="form-control"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={e => updateData('user', e)}
              />
            </div>
            <div className="form-group last mb-3">
              <input
                placeholder="Contraseña"
                type="password"
                className="form-control"
                id="password"
                onChange={e => updateData('pass', e)}
              />
            </div>

            <input disabled={!canSubmit} type="submit" value="Iniciar sesión" className="btn btn-block btn-primary" />

            {/*
              <span className="d-block text-center mt-3 text-muted">&mdash;&mdash; o &mdash;&mdash;</span>

              <div className="mt-4 text-center">
                <Link to="/signup" className="forgot-pass">¿Has olvidado la contraseña?</Link>
              </div>
              */
              // <span className="d-block text-center my-4 text-muted">&mdash; or &mdash;</span>
            }

            <div className="social-login" style={{ display: 'none' }}>
              <a href="/" className="facebook btn d-flex justify-content-center align-items-center">
                <span className="icon-facebook mr-3" />
                {' '}
                Login with Facebook
              </a>
              <a href="/" className="twitter btn d-flex justify-content-center align-items-center">
                <span className="icon-twitter mr-3" />
                {' '}
                Login with  Twitter
              </a>
              <a href="/" className="google btn d-flex justify-content-center align-items-center">
                <span className="icon-google mr-3" />
                {' '}
                Login with  Google
              </a>
            </div>
          </form>
        </div>
      </Row>
      <Row className="justify-content-center mt-3">
        <div className="box text-center">
          ¿No tienes cuenta?
          {' '}
          <Link to="/signup">Regístrate</Link>
        </div>
      </Row>
    </Container>
  )
}

export default loginPage;
