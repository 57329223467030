import React, { useEffect, useState } from 'react';
import {
 Col, Container, Row,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { RootState } from 'packages/_core/app.store';
import { useDispatch, useSelector } from 'react-redux';
import { UserDispatch } from 'packages/_core/user/thunk.type';
import { thunkLogout } from 'packages/_core/user/thunks/logout.thunk';

import './logout.scoped.scss';

export const LogoutPage = () => {
  const user = useSelector((state: RootState) => state.user)
  const dispatch: UserDispatch = useDispatch()

  useEffect(() => {
    dispatch(thunkLogout());
  }, [])

  if (!user) { // User signed up
    return <Redirect to="/login" />
  }

  return (
    <Container className="pt-5">
      <Row className="justify-content-center">
        <div className="box">
          <h2>¡Hasta la próxima!</h2>
        </div>
      </Row>
    </Container>
  )
}

export default LogoutPage;
