import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './active-test-notify.scoped.scss'

const ProgressCardComponent = ({ testId }: { testId: number }) => (
  <Link to={`/test/${testId}/play`} className="card d-flex flex-row justify-content-between p-4">
    <div className="icon-action">
      <FontAwesomeIcon icon={faPlayCircle} />
    </div>
    <div className="text-container">
      <h3>Tienes un test pendiente</h3>
      <span>
        Continuar ahora
        <FontAwesomeIcon className="appears" icon={faArrowRight} />
      </span>
    </div>
  </Link>
)

export default ProgressCardComponent;
