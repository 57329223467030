import TLibrary from 'packages/_core/types/TLibrary';
import { LibraryStore } from '../types';

/**
 * Updates a library from the libraries array given the library with the new data
 * @param state Current store state
 * @param newLib New library data to update the store
 */
export const updateLibraryReducer = (state: LibraryStore, newLib: TLibrary): LibraryStore => {
    let found = false;
    const updatedLibrariesList = state.libraries.map((lib) => {
        if (lib.id !== newLib.id) return lib;
        found = true;
        return { ...lib, ...newLib };
    })

    if (found) return { ...state, libraries: [...updatedLibrariesList] }
    return state;
}
