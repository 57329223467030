import React from 'react';
/*
import {
  StackedAreaChart
} from '@opd/g2plot-react';
*/
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
 ResponsiveContainer, LineChart, Line, Bar, ComposedChart, XAxis,
} from 'recharts';

import './user-dedication-stats.scoped.scss';
import treasure from '../../../../assets/icons/svg/treasure-open.svg';

const data = [
  {
    name: 'L',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'M',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'X',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'J',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'V',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'S',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'D',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

class UserDedicationStats extends React.Component {
  render() {
    return (
      <>
        <div className="d-flex flex-row p-4">
          <div className="treasure-container">
            <img src={treasure} alt="Treasure!" />
          </div>
          <div className="w-100 pl-2">
            <div style={{ fontSize: '1.3rem' }}>
              Siguiente nivel
              <span
                style={{
                  float: 'right',
                  fontSize: '0.75rem',
                  marginTop: '0.6rem',
                  verticalAlign: 'baseline',
                  fontWeight: 'bold',
                }}
              >
                80 / 100 EXP
              </span>
            </div>
            <ProgressBar
              className="UserDedicationXpBar"
              variant="warning"
              striped
              animated
              now={60}
            />
          </div>
        </div>
        <div className="separator">
          <h5>Eficiencia</h5>
        </div>
        <ResponsiveContainer aspect={2}>
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <Line type="monotone" dataKey="pv" stroke="#8884d8" strokeWidth={2} />
            <Bar dataKey="pv" barSize={20} fill="rgba(100, 150, 255, 0.2)" />
            <XAxis dataKey="name" />
          </ComposedChart>
        </ResponsiveContainer>
        <div className="footer">
          <small>
            <b>Próximamente.</b>
            {' '}
            Estas graficas aún no funcionan.
          </small>
        </div>
      </>
    );
  }
}

export default UserDedicationStats;
