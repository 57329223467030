import React, { useState } from 'react';

// Bootstrap
import Col from 'react-bootstrap/Col';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import './question-creation.scoped.scss';
import { LQuestion } from 'packages/_core/types/TQuestion';
import { Question } from 'packages/_core/models/Question';
import { Button } from 'react-bootstrap';
import Answer from 'packages/_core/models/Answer';

import { HotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { addQuestion } from 'packages/_core/library/actions';
import { isMobileDevice } from 'packages/FreeUserApp/services/device.service';
import { thunkCreateQuestion } from 'packages/_core/library/thunks/question-create.thunk';
import { LibraryDispatch } from 'packages/_core/library/thunk.type';
import bgImg from '../../../../../assets/illustrations/to-the-moon.svg';
import CreationHint from './creation-hint.component';
import QuestionForm from '../question-form/question-form.component';

const keyMap = {
  CANCEL_CREATION: 'Escape',
};

const handlers = {
  CANCEL_CREATION: (event: any) => console.log('Move up hotkey called!', event),
};

const QuestionCreation = (props: { library: number, createCallback: (create: boolean) => void }) => {
  const questionCreator = (id?: number) => Question.create({
    id,
    library: props.library,
    answers: [Answer.create({ correct: true })],
  })

  const [question, setQuestion] = useState(questionCreator())
  const [canSave, setCanSave] = useState(false)
  const [modified, setModified] = useState(false)

  const activeAlert = withReactContent(Swal);
  // const dispatch = useDispatch();
  const dispatch: LibraryDispatch = useDispatch();

  const hasData = () => (question.text.length !== 0
    || question.answers.length > 1
    || (question.answers.length === 1 && question.answers[0].text.length !== 0))

  const cancelCreation = () => {
    if (!hasData()) {
      props.createCallback(false);
      return;
    }

    activeAlert.fire({
      icon: 'warning',
      title: '¡No has guardado!',
      text: '¿Seguro que quieres descartar la pregunta?',
      confirmButtonText: 'Descartar pregunta',
      showCancelButton: true,
      cancelButtonText: 'No, quiero guardarla',
      confirmButtonColor: '#cccccc',
      cancelButtonColor: '#62b53c',
      allowOutsideClick: false,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.createCallback(false)
      }
    })
  }

  const onQuestionUpdate = (newQuestion: LQuestion) => {
    setQuestion(Question.removeEmptyAnswers(newQuestion));
    setModified(true);
  }

  const saveChanges = () => {
    if (!canSave) return;
    dispatch(addQuestion(question));
    dispatch(thunkCreateQuestion(question));
    setQuestion(questionCreator((question.id - 1)));
    // play feedback
    activeAlert.fire({
      text: '¡Pregunta guardada!',
      toast: true,
      position: (isMobileDevice()) ? 'bottom' : 'top',
      timer: 5000,
      showConfirmButton: false,
    })
  }

  const updateSave = (canDo: boolean) => {
    if (canDo && modified) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <div key={`question-card-${question.id}`} className="d-flex QuestionCardRow mb-5" style={{ flexWrap: 'wrap' }}>
        <Col
          sm={2}
          className="QuestionInfoCol d-flex flex-wrap align-content-between justify-content-center"
          style={{ backgroundImage: `url(${bgImg})` }}
        >
          <div className="QuestStats">
            Nueva pregunta
          </div>
        </Col>
        <Col className="QuestionContent">
          <QuestionForm
            question={question}
            onSaveChange={updateSave}
            onUpdate={onQuestionUpdate}
            forceSave={saveChanges}
            editing
          >
            <CreationHint />
            <div className="text-center mt-4">
              <Button variant="primary" disabled={!canSave} onClick={saveChanges}>Guardar pregunta</Button>
              <Button variant="outline-primary ml-3" onClick={cancelCreation}>Cancelar</Button>
            </div>
          </QuestionForm>
        </Col>
      </div>
    </HotKeys>
  );
}

export default QuestionCreation;
