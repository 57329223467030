import React, { useEffect, useState } from 'react';
import {
 Redirect, useHistory, useLocation,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { thunkCreateTest } from 'packages/_core/tests/thunks/active.thunk';
import { setActiveTest } from 'packages/_core/tests/actions';
import { RootState } from 'packages/_core/app.store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { TestDispatch } from 'packages/_core/tests/thunk.type';
import { TestConfig } from 'packages/_core/models/TestConfig';
import TTest, { TType } from 'packages/_core/types/TTest';
import { thunkUpdateTest } from 'packages/_core/tests/thunks/tests.thunk';
import { Test } from 'packages/_core/models/Test';
import NewTestOptions from './components/settings-form/settings-form.component';
import LibraryFactory from './components/library-selection/library-factory.component';
import TestCreationLoader from './components/creation-loader/loading.component';
import TTestCreationParams from './types/TCreation';

enum Stage {
  libraries = 0,
  options = 1,
  creating = 2,
  redirecing = 3,
}

const activeAlert = withReactContent(Swal);

const TestCreationHoc = () => {
  const { tests } = useSelector((state: RootState) => state);

  const history = useHistory();
  const location = useLocation();

  const [testId, setTestId] = useState(-1);
  const [testConfig, setTestConfig] = useState(new TestConfig());
  const [stage, setStage] = useState<Stage>(Stage.libraries);

  const dispatch: TestDispatch = useDispatch();

  const initDefaults = () => {
    if (location.state) {
      const config = location.state as TTestCreationParams;
      // setTestConfig({ ...new TestConfig(), ...config as TTConfig });
      updateConfig(config);
      if (config.libraries && config.libraries.length > 0) {
        if (config.force && config.force === true) {
          createTest();
        }
      }
    }
  }

  const updateConfig = (newParams: TTestCreationParams) => {
    setTestConfig({ ...testConfig, ...newParams });
  }

  const isPractice = () => {
    if (!location.state) return false;
    const config = location.state as TTestCreationParams;
    return config.type === TType.practice;
  }

  useEffect(() => {
    if (tests.active !== null && !isPractice()) {
      activeAlert.fire({
        icon: 'info',
        title: '¿Otro test?',
        text: 'Tienes un test sin acabar. ¿Quieres crear otro nuevo?',
        confirmButtonText: 'Descartar y crear nuevo',
        showCancelButton: true,
        cancelButtonText: 'Continuar el anterior',
        confirmButtonColor: '#cccccc',
        cancelButtonColor: '#62b53c',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (tests.active) {
            const activeTest = Test.findById(tests.list, tests.active)
            if (activeTest) dispatch(thunkUpdateTest(activeTest)); // clear active test
          }
          initDefaults();
        } else {
          history.push(`/test/${tests.active}`);
        }
      })
    } else {
      initDefaults();
    }
  }, []);

  const selectLibraries = (libraries: number[]) => {
    setTestConfig({ ...testConfig, libraries })
    goToNextStage();
  }

  const goToNextStage = () => {
    setStage(stage + 1); // Go to next wizzard stage
  };

  const goToLastStage = () => {
    if (stage === 0) {
      history.goBack();
    } else {
      setStage(stage - 1); // Go to next wizzard stage
    }
  };

  const createTest = () => {
    setStage(Stage.creating);
    dispatch(thunkCreateTest(testConfig)).then(
      (response) => {
        setTestId(response.id);
        setStage(Stage.redirecing);
      },
      (error) => {
        if (location.state) {
          // if we come from a "spcecial" place with default params, go back
          history.goBack();
        } else {
          // otherwise, we are following the default test creation workflow, go to last stage
          setStage(Stage.options);
        }
      },
    )
  };

  if (stage === Stage.redirecing && testId !== -1) {
    return <Redirect to={`/test/${testId}/play`} />
  }
  switch (stage) {
    case Stage.creating: // loading page (while test is being created)
      return <TestCreationLoader />;
    case Stage.options: // choose test options
      return (
        <NewTestOptions
          backCallback={goToLastStage}
          submitCallback={createTest}
          updateConfig={updateConfig}
          config={testConfig}
        />
      );
    case Stage.libraries: // select test libraries
    default:
      return <LibraryFactory initial={testConfig.libraries as number[]} backCallback={goToLastStage} submitCallback={selectLibraries} />;
  }
}
export default TestCreationHoc;
