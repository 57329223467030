import React, { useEffect, useRef, useState } from 'react';

// Bootstrap
import Form from 'react-bootstrap/Form';

import TextareaAutosize from 'react-textarea-autosize';

import InputGroup from 'react-bootstrap/InputGroup';

import '../question-creation/question-creation.scoped.scss';
import { LQuestion } from 'packages/_core/types/TQuestion';
import Answer from 'packages/_core/models/Answer';

import { isMobileDevice } from 'packages/FreeUserApp/services/device.service';

import './question-form.scoped.scss';
import { InputRef } from './types';

const QuestionForm = ({ editing, ...props }:
  React.PropsWithChildren<{
    question: LQuestion,
    editing: boolean,
    onUpdate(newData: LQuestion): void,
    onSaveChange(canSave: boolean): void,
    forceSave(): void
  }>) => {
  const [question, setQuestion] = useState<LQuestion>(props.question)

  // focus related
  const questionTextRef = useRef<HTMLTextAreaElement | null>(null)
  const inputRefs = useRef<InputRef>({})

  useEffect(() => {
    // if editing === true, start editing, otherwise cancel
    if (editing) {
      startEditing();
    } else {
      discardChanges();
    }
  }, [editing])

  useEffect(() => {
    props.onUpdate(question)
    props.onSaveChange(canSave())
  }, [question])

  useEffect(() => {
    if (question.id !== props.question.id) {
      questionTextRef?.current?.focus();
      setQuestion({ ...props.question })
    }
  }, [props.question])

  /**
   * Prevents new line events
   * @param event input event
   */
  const preventNewLine = (event: any) => {
    if (event.target.value.slice(-1).match(/\n/)) {
      event.target.value = event.target.value.substr(
        0,
        event.target.value.length - 1,
      );
    }
  };

  /**
   * STATE CHECKERS
   */
  const canSave = () => (question.text.length !== 0
      && (question.answers.length > 1
      || (question.answers.length === 1 && question.answers[0].text.length !== 0)));

  /**
   * EVENT HANDLERS
   */
  /**
   * Handle question text key events
   * @param e key event
   */
  const questionKeypress = (e: any) => {
    const key = e.keyCode || e.which;
    if (key === 13 || key === 229) {
      inputRefs.current[question.answers[0].id].focus();
    }
  }

  /**
   * Handles answer text key events
   * @param e key event
   * @param id answer id
   */
  const answerKeypress = (e: any, id: number) => {
    const key = e.keyCode || e.which;
    preventNewLine(e);
    switch (key) {
      case 13: { // Enter
        const lastAnswer = question.answers.slice(-1)[0];
        if (lastAnswer.id === id && lastAnswer.text.length === 0 && canSave()) {
          saveToDb() // create question on enter pressed when no text in last answer
        } else if (lastAnswer.id === id && lastAnswer.text.length !== 0) {
          addNewAnswer(false);
        } else {
          const next = getValue(id, 'next')
          if (next) inputRefs.current[next].focus();
          console.info('next', next)
        }
        break;
      }
      case 17: // Ctrl key
        checkUpdated(id)
        break;
      case 8: { // backspace
        // Delete question when backspace and no text for this answer
        const prev = getValue(id, 'prev')
        if (inputRefs.current[id]?.value.length === 0) {
          if (prev === null) {
            // Go to question text
            questionTextRef.current?.focus();
          } else {
            inputRefs.current[prev].focus();
            const numChars = inputRefs.current[prev].value.length;
            inputRefs.current[prev].selectionStart = numChars;
            inputRefs.current[prev].selectionEnd = numChars;
            console.log('go to prev', prev, inputRefs.current[prev])
          }
        }
        break;
      }
      default:
        // console.log('no keybinding', key)
    }
  }

  const getValue = (id: number, pos: 'prev'|'next') => {
    const currentIndex = question.answers.findIndex(ele => ele.id === id)
    const offset = (pos === 'prev') ? currentIndex - 1 : currentIndex + 1;
    return (currentIndex >= 0 && question.answers[offset]) ? question.answers[offset].id : null;
  }

  /**
   * Returns the inputRefs keys as number
   */
  const keysAsNumber = (): number[] => Object.keys(inputRefs.current).map(ele => Number(ele));

  /**
   * Adds a new empty answer to the array
   */
  const addNewAnswer = (correct: boolean = false, focus: boolean = false) => {
    const lastAnswer = question.answers.slice(-1)[0];
    if (lastAnswer?.text.length !== 0) {
      const least = Math.min(...keysAsNumber())
      const newAns = Answer.create({
        id: (least < 0) ? (least - 1) : -1,
        correct,
      })
      setQuestion({
        ...question,
        answers: [...question.answers, newAns],
      })
    } // else if (lastAnswerRef && focus) lastAnswerRef.focus();
  }

  /**
   * Deletes the answer if there are more than 1 remaining and its not the last
   * @param id answer id
   * @param text answer text
   */
  const deleteUnfilled = (id: number, text: string) => {
    const ansIndex = question.answers.findIndex(ele => ele.id === id)
    if (ansIndex > -1
      && (ansIndex !== question.answers.length - 1)
      && question.answers[ansIndex].text.length === 0
      && question.answers.length > 1) {
        const newArray = [...question.answers];
        newArray.splice(ansIndex, 1)
        setQuestion({
          ...question,
          answers: newArray,
        })

        // Remove item with id === key
        delete inputRefs.current[id];
    }
  }

  const textUpdated = (value: string) => {
    setQuestion({
      ...question,
      text: value,
    })
  }

  const answerUpdated = (id: number, value: string) => {
    const position = question.answers.findIndex(ans => ans.id === id)
    const ans = { ...question.answers[position] };
    ans.text = value;

    const newArray = [...question.answers]
    newArray.splice(position, 1, ans)
    setQuestion({
      ...question,
      answers: newArray,
    })

    if (isMobileDevice()) {
      addNewAnswer();
    }
  }

  const checkUpdated = (id: number, value?: boolean) => {
    const position = question.answers.findIndex(ans => ans.id === id)
    const ans = { ...question.answers[position] };

    ans.correct = value || !question.answers[position].correct;
    const newArray = [...question.answers]
    newArray.splice(position, 1, ans)
    setQuestion({
      ...question,
      answers: newArray,
    })
  }

  const addRef = (input: HTMLTextAreaElement | null, id: number) => {
    if (input && !inputRefs.current[id]) {
      inputRefs.current[id] = input;
      console.log(inputRefs)
    }
  }

  const startEditing = () => {
    addNewAnswer(false, true)
  }

  const discardChanges = () => {
    setQuestion(props.question);
  }

  const saveToDb = () => {
    if (canSave()) props.forceSave();
  }

  return (
    <>
      <div className="QuestionContent">
        <Form.Group controlId="formBasicEmail">
          <TextareaAutosize
            className="form-control"
            style={{ fontWeight: 500, fontSize: '1.15rem' }}
            onChange={(e: any) => { preventNewLine(e); textUpdated(e.target.value) }}
            onKeyDownCapture={questionKeypress}
            value={question.text}
            placeholder="¡Escribe un título!"
            autoFocus
            ref={questionTextRef}
            disabled={!editing}
          />
          <Form.Text className="text-muted pl-2">
            Título de la pregunta
          </Form.Text>
        </Form.Group>
        {
          question.answers.map((ans, index) => (
            <InputGroup key={`q-${question.id}-answer-input-${ans.id}`} className="mt-3">
              <InputGroup.Prepend>
                <Form.Check
                  type="checkbox"
                  name={`q-${question.id}-answer-check-${ans.id}`}
                  id={ans.id.toString()}
                  style={{ display: 'inline-block' }}
                  checked={ans.correct}
                  onChange={(e: any) => checkUpdated(ans.id, e.target.checked)}
                  disabled={!editing}
                />
              </InputGroup.Prepend>
              <TextareaAutosize
                className="form-control"
                value={ans.text}
                onKeyDown={e => answerKeypress(e, ans.id)}
                onChange={(e: any) => { preventNewLine(e); answerUpdated(ans.id, e.target.value) }}
                autoFocus={question.answers.length !== 1}
                ref={input => addRef(input, ans.id)}
                onBlur={() => deleteUnfilled(ans.id, ans.text)}
                disabled={!editing}
              />
            </InputGroup>
          ))
        }
      </div>
      <div className="action-buttons text-center mt-4 mb-2">
        {
          props.children
        }
      </div>
    </>
  );
}

export default QuestionForm;
