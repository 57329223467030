import React, { useEffect } from 'react';
import './library-item.scoped.scss';

import { motion } from 'framer-motion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
// import 'components/library-card/node_modules/react-circular-progressbar/dist/styles.css';

import * as Check from 'packages/FreeUserApp/shared/checkbox/checkbox.component';
import TLibrary from 'packages/_core/types/TLibrary';
import { Library } from 'packages/_core/library/domain/Library';

export interface props extends TLibrary {
  checked: boolean,
  clicked: (id: number) => void,
}

const LibrarySelectionCard = (library: props) => (
  <motion.div
    whileTap={{ scale: 0.95 }}
    key={library.id}
  >
    <div
      className="card"
      key={library.id}
      onClick={() => library.clicked(library.id)}
      onKeyPress={() => library.clicked(library.id)}
      role="button"
      tabIndex={0}
    >
      <div className="card-body">
        <Row>
          <Col xs={2} md={2} className="pr-0">
            <CircularProgressbarWithChildren
              backgroundPadding={0}
              value={Library.getProgressionPercentage(library.stats)}
              styles={buildStyles({
                pathColor: '#FF8C8C',
                trailColor: '#f3f3f3',
              })}
            >
              <div style={{ marginTop: -5 }}>
                <Check.Check isChecked={library.checked} width="100%" />
              </div>
            </CircularProgressbarWithChildren>
          </Col>
          <Col xs={10} md={10}>
            <h4 className="pt-0 mb-1">{library.name}</h4>
            <span>{library.description}</span>
          </Col>
        </Row>
      </div>
    </div>
  </motion.div>
  )
export default LibrarySelectionCard;
