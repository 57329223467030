import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'packages/_core/app.store';
import { LibraryDispatch } from 'packages/_core/library/thunk.type';
import { thunkGetLibraries } from 'packages/_core/library/thunks/library.thunk';
import LibrariesFactory from './libraries.factory';
import NoLibrariesFound from './components/no-libraries-found/no-libraries-found.component';

export default function LibrariesPage() {
  const dispatch: LibraryDispatch = useDispatch();

  const { libraries } = useSelector((state: RootState) => state.libraries);

  useEffect(() => {
    dispatch(thunkGetLibraries())
  }, []);

  if (libraries.length > 0) {
    return (
      <>
        <Container className="main-container-padded">
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: 7, order: 1 }}>
              <LibrariesFactory libraries={libraries} />
            </Col>
            <Col className="d-none d-lg-block" xs={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 5, order: 2 }}>
              <Card body className="mb-3">
                <Link
                  to="/librerias/nueva"
                  className="btn btn-outline-primary btn-lg btn-block"
                >
                  Crear librería nueva
                </Link>
              </Card>
              <Card className="mb-3">
                <Card.Header>
                  Ayuda
                </Card.Header>
                <Card.Body>
                  Las librerías son el lugar donde guardar todas tus preguntas.
                  <br />
                  <br />
                  Puedes organizarlas como quieras, aunque mucha gente suele hacerlo por temas, o partes de ellos.
                  Un ejemplo de librería es:
                  <i>&quot;Tema 1: Legislación Española&quot;</i>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  return (
    <Container className="main-container-padded">
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <NoLibrariesFound />
        </Col>
      </Row>
    </Container>
  )
}
