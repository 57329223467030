import { LQuestion } from 'packages/_core/types/TQuestion';
import { AppThunk } from '../thunk.type';
import {
 deleteQuestion,
} from '../actions';
import { ResponseQuestion } from '../types/response-types';

export const thunkDeleteQuestion = (question: LQuestion): AppThunk => async (
    dispatch,
    getState,
    api,
  ) => api.delete<ResponseQuestion>(`/question/${question.id}`).then(
    ({ data }) => {
      dispatch(deleteQuestion(question)); // delete question
      return Promise.resolve(true);
    },
    (err) => err,
  ).catch((e) => e)
