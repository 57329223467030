import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import boredIcon from '../../../assets/illustrations/bored.svg';

const TestNotValid = () => (
  <div style={{ backgroundImage: boredIcon }} className="text-center">
    <img style={{ maxWidth: '450px' }} src={boredIcon} alt="Confused person" />
    <br />
    <h4>No hemos encontrado este resultado</h4>
    <h5>¿Cómo has llegado aquí...?</h5>
    <Link className="btn btn-outline-primary mt-4" to="/resultados">
      <FontAwesomeIcon className="mr-2" icon={faChevronLeft} />
      Volver a mis resultados
    </Link>
  </div>
)
export default TestNotValid;
