import TLibrary from 'packages/_core/types/TLibrary';
import { LQuestion } from 'packages/_core/types/TQuestion';
import { LibraryStore } from '../types';

/**
 * Adds a new question to the library
 * @param state Current store state
 * @param newQuestion New question data
 */
export const addQuestionReducer = (state: LibraryStore, newQuestion: LQuestion): LibraryStore => {
    let index = -1;
    const library = state.libraries.find((lib, idx) => {
        if (lib.id === newQuestion.library) {
            index = idx;
            return true;
        }
        return false;
    })

    // remove empty answers
    newQuestion.answers = newQuestion.answers.filter(ans => ans.text.length > 0)

    if (index === -1 || !library) return state;
    library.stats.total += 1;
    library.questions.unshift(newQuestion);
    const tmp = state.libraries;
    if (!tmp) return state;
    tmp.splice(index, 1, library)
    return { ...state, libraries: [...tmp] }
}
