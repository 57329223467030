import React from 'react';

import './question-card.scoped.scss';
import TQuestion from 'packages/_core/types/TQuestion';
import { ProgressBar } from 'react-bootstrap';
import SimpleStats from 'packages/_core/types/TSimpleStats';
import {
    CircularProgressbarWithChildren,
    buildStyles,
  } from 'react-circular-progressbar';

export interface relativeQuestion extends TQuestion {
  relativeId: number
}

const QuestionCardStats = ({ stats }: { stats: SimpleStats | undefined }) => {
  const success = (stats?.success || 0);
  const fail = (stats?.fail || 0);
  const total = Math.round((success / (fail + success)) * 100) || 0;

  return (
    <>
      <div className="QuestStats d-none d-sm-block">
        <CircularProgressbarWithChildren
          background
          backgroundPadding={4}
          styles={buildStyles({
                backgroundColor: '#ffffff',
                trailColor: 'transparent',
                pathColor: '#467F8E',
              })}
          value={total}
        >
          <div>
            <strong style={{ fontSize: 22, fontWeight: 200, letterSpacing: '-0.12rem' }}>
              {total}
            </strong>
            %
            <br />
            <span className="info-text">Aciertos</span>
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <ProgressBar
        variant="secondary"
        className="d-flex d-sm-none w-100 card-progress"
        now={total}
        label={`${total}% aciertos`}
      />
      <div className="d-none d-sm-block stats-numbers">
        <div>
          Aciertos:
          <span>{success}</span>
        </div>
        <div>
          Fallos:
          <span>{fail}</span>
        </div>
        <div>
          Omisiones:
          <span>{stats?.empty}</span>
        </div>
      </div>

    </>
  );
}

export default QuestionCardStats;
