import React from 'react';

import TLibrary from 'packages/_core/types/TLibrary';
import { Form } from 'react-bootstrap';

import './library-list.scss'

export default function LibraryListComponent({ searchText, libraries }: { searchText: string, libraries: TLibrary[] }) {
    if (searchText === '') {
    /* return (
      <Form.Text className="text-muted">
        Por ejemplo &quot;Tema 1, legislación española&quot;
      </Form.Text>
        ); */
        return <></>;
    }

    const matches: JSX.Element[] = [];

    let i = 0;
    while (i < libraries.length && i < 4) {
        const ele = libraries[i];
        if (ele.name?.toLowerCase().includes(searchText)) {
            matches.push(<div style={{ fontWeight: 600 }}>{ ele.name }</div>);
        }
        i++;
    }

    if (matches.length === 0) {
        return (
          <Form.Text id="library-name" className="text-muted input-suggestions">
            No tienes librerías parecidas ¡Quizá sea un buen nombre!
          </Form.Text>
        )
    }

    return (
      <Form.Text id="library-name" className="text-muted input-suggestions">
        <div>Tus librerías similares</div>
        { matches }
      </Form.Text>
    );
}
