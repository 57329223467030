import axios from 'axios';
import { addErrorHandler } from 'packages/FreeUserApp/interceptors/api-error.interceptor';

const api = axios.create({
  withCredentials: true,
  responseType: 'json',
});
api.defaults.baseURL = process.env.REACT_APP_API;

// Add API middleware
addErrorHandler(api);

export default api;
