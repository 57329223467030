import TTest from 'packages/_core/types/TTest'

// TODO: Use strings file
const getValorationText = ({ stats }: TTest): string => {
    const percent = stats.success / (stats.fail + stats.empty);
    if (percent === 1) return '¡Perfecto!';
    if (percent > 0.7) return '¡Muy bueno!';
    if (percent >= 0.5) return 'No está mal';
    if (percent > 0.3) return 'Es cuestión de práctica';
    return 'Recomendamos estudiar más...';
}
export default getValorationText
