import React from 'react';
import TLibrary from 'packages/_core/types/TLibrary';
import { TTContent } from 'packages/_core/types/TTest';
import {
 Accordion, Card,
} from 'react-bootstrap';
import Content from '../content-resume/content-resume.component';
import LibraryToggle from './library-toggle.component';

import './library-factory.scss';

const Libraries = ({ libraries }: { libraries: TLibrary[] }) => (
  <>
    {libraries.map((_library) => (_library.content) && (
      <Accordion key={_library.id}>
        <Card body className="test-library-card">
          <LibraryToggle library={_library} eventKey={_library.id.toString()} />
          <Accordion.Collapse eventKey={_library.id.toString()}>
            <Card.Body>
              <Content content={_library.content as TTContent[]} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      ))}
  </>
  )
export default Libraries;
