import React, { useEffect, useState } from 'react';

import TopicTag from 'packages/FreeUserApp/shared/topic-tag/topic-tag.component';
import { motion } from 'framer-motion';
import TLibrary from 'packages/_core/types/TLibrary';
import { FilterLibraries } from './filter.types';

export const LibraryFilter = (props: {
    libraries: TLibrary[],
    selectionCallback: (selected: FilterLibraries[]) => void,
}) => {
    const [filteredLibraries, setFilteredLibraries] = useState<FilterLibraries[]>([])

    useEffect(() => {
        const newLibs: FilterLibraries[] = [];
        props.libraries.forEach((lib) => {
            let selected = false;
            if (filteredLibraries.find((ele) => ele.id === lib.id)) {
                selected = true;
            }
            newLibs.push({ ...lib, selected });
        })
        setFilteredLibraries(newLibs);
    }, [props.libraries])

    const selectLibrary = (library: FilterLibraries) => {
        const tmp = [...filteredLibraries];
        const index = tmp.findIndex((ele) => ele.id === library.id);
        if (index >= 0) tmp[index].selected = !tmp[index].selected;
        setFilteredLibraries(tmp);
        props.selectionCallback(tmp);
    }

    return (
      <>
        {filteredLibraries.map((lib) => (
          <motion.div
            style={{ opacity: (lib.selected) ? 1 : 0.5, cursor: 'pointer' }}
            whileTap={{ scale: 0.9 }}
            onClick={() => selectLibrary(lib)}
            className="d-inline-block"
            key={`library_filter_${lib.id}`}
          >
            <TopicTag {...lib} />
          </motion.div>
    ))}
      </>
)
    }
export default LibraryFilter;
