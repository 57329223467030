import TError from '../types/TError';

export const SET_ERROR = 'SET_ERROR';

export type ErrorStore = TError | null;

interface SetError {
  type: typeof SET_ERROR;
  payload: TError | null;
}

export type ActionTypes = SetError;
