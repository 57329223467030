import TLibrary from 'packages/_core/types/TLibrary';
import { Question } from '../models/Question';
import { LQuestion } from '../types/TQuestion';
import * as Library from './types';

export function setLibraries(libraries: Array<TLibrary>): Library.ActionTypes {
  return {
    type: Library.SET_LIBRARIES,
    payload: libraries,
  };
}

export function addLibrary(library: TLibrary): Library.ActionTypes {
  return {
    type: Library.ADD_LIBRARY,
    payload: library,
  };
}

export function updateLibrary(library: TLibrary): Library.ActionTypes {
  return {
    type: Library.UPDATE_LIBRARY,
    payload: library,
  };
}

export function removeLibrary(id: number): Library.ActionTypes {
  return {
    type: Library.REMOVE_LIBRARY,
    payload: id,
  };
}

export function addQuestion(question: LQuestion): Library.ActionTypes {
  return {
    type: Library.ADD_QUESTION,
    payload: Question.removeEmptyAnswers(question),
  };
}

export function updateQuestion(question: LQuestion): Library.ActionTypes {
  return {
    type: Library.UPDATE_QUESTION,
    payload: Question.removeEmptyAnswers(question),
  };
}

export function replaceQuestion(question: LQuestion): Library.ActionTypes {
  return {
    type: Library.REPLACE_QUESTION,
    payload: Question.removeEmptyAnswers(question),
  };
}

export function deleteQuestion(question: LQuestion): Library.ActionTypes {
  return {
    type: Library.DELETE_QUESTION,
    payload: question,
  };
}

export function setLoadingLibraries(newLoading: boolean): Library.ActionTypes {
  return {
    type: Library.SET_LOADING_LIBRARIES,
    payload: newLoading,
  };
}
