import { Library } from 'packages/_core/models/Library';
import { LQuestion } from 'packages/_core/types/TQuestion';
import { LibraryStore } from '../types';

/**
 * Updates a question given a new question object with the new data
 * @param state Current store state
 * @param newLib New question data to update the store
 */
export const replaceQuestionReducer = (state: LibraryStore, newQuest: LQuestion): LibraryStore => {
  if (!newQuest.parent) return state;

  const libIndex = Library.findIndexById(state.libraries, newQuest.library);
    const stateLibs = [...state.libraries];
    if (libIndex > -1) { // Library exists
      const questIndex = Library.findQuestionIndexById(stateLibs[libIndex].questions, newQuest.parent.id) // Find the non-saved question
      if (questIndex > -1) { // Question exists
        stateLibs[libIndex].questions[questIndex] = newQuest;
        return { ...state, libraries: [...stateLibs] }
      }
    }

    return state;
}
