import TLibrary from 'packages/_core/types/TLibrary';
import { LQuestion } from '../types/TQuestion';
import TResponse from '../types/TResponse';

export const SET_LIBRARIES = 'SET_LIBRARIES';
export const ADD_LIBRARY = 'ADD_LIBRARY';
export const UPDATE_LIBRARY = 'UPDATE_LIBRARY';
export const REMOVE_LIBRARY = 'REMOVE_LIBRARY';
export const ADD_QUESTION = 'ADD_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const REPLACE_QUESTION = 'REPLACE_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const SET_LOADING_LIBRARIES = 'SET_LOADING_LIBRARIES';

export type LibraryStore = {
  libraries: Array<TLibrary>;
  updated: Date | null;
  loading: boolean;
};

interface SetLibraries {
  type: typeof SET_LIBRARIES;
  payload: Array<TLibrary>;
}

interface AddLibrary {
  type: typeof ADD_LIBRARY;
  payload: TLibrary;
}

interface UpdateLibrary {
  type: typeof UPDATE_LIBRARY;
  payload: TLibrary;
}

interface RemoveLibrary {
  type: typeof REMOVE_LIBRARY;
  payload: number; // Library ID to remove
}

interface AddQuestion {
  type: typeof ADD_QUESTION;
  payload: LQuestion;
}

interface UpdateQuestion {
  type: typeof UPDATE_QUESTION;
  payload: LQuestion;
}

interface ReplaceQuestion {
  type: typeof REPLACE_QUESTION;
  payload: LQuestion;
}

interface DeleteQuestion {
  type: typeof DELETE_QUESTION;
  payload: LQuestion;
}

interface SetLoadingLibraries {
  type: typeof SET_LOADING_LIBRARIES;
  payload: boolean;
}

export type ActionTypes =
  | SetLibraries
  | AddLibrary
  | UpdateLibrary
  | RemoveLibrary
  | AddQuestion
  | UpdateQuestion
  | ReplaceQuestion
  | DeleteQuestion
  | SetLoadingLibraries;

/**
 * Interfaces to map server responses
 */
export interface ResponseLibraries extends TResponse {
  data: TLibrary[];
}
