import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faHistory, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { TNavbarSubmenu } from '../app/components/navbar/types';

export const LIBRARY_DETAIL: TNavbarSubmenu = {
    links: [
        {
            route: '/librerias/:id',
            text: 'Librería',
        },
        /* {
            route: '/librerias/:id/historial',
            text: 'Historico',
        }, */
    ],
    buttons: [
        {
            route: '/librerias/:id/papelera',
            text: 'Papelera',
            icon: faHistory,
        },
        /* {
            route: '/librerias/:id/papelera',
            text: 'Papelera',
            icon: faHistory,
        }, */
    ],
}

export const LIBRARY_CREATION: TNavbarSubmenu = {
    links: [
        {
            route: '/librerias/nueva',
            text: 'Nueva librería',
        },
    ],
}

export const LIBRARY_LIST: TNavbarSubmenu = {
    links: [
        {
            route: '/librerias',
            text: 'Mis librerías',
        },
        /* {
            route: '/librerias/explora',
            text: 'Explora',
        }, */
    ],
    buttons: [
        {
            route: '/librerias/nueva',
            text: 'Crear librería nueva',
            icon: faPlus,
        },
    ],
}
