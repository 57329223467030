import React from 'react';

import { Link, NavLink } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faUser, faDotCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faPowerOff, faFire, faPlay, faChartLine, faList, faEllipsisH,
 } from '@fortawesome/free-solid-svg-icons';
import * as Constants from 'constants/constants';

import './navbar.scoped.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'packages/_core/app.store';
import NavbarSubComponent from './navbar-sub.component';
import { TNavbar } from './types';

import DefaultAvatar from '../../../../assets/icons/svg/users/cubies-find.svg';

export const MainMenu = ({ submenu = undefined, disableMargin }: TNavbar) => {
const user = useSelector((state: RootState) => state.user)
return (
  <div>
    <Navbar
      collapseOnSelect
      expand="md"
      variant="light"
      className={`${(!submenu && !disableMargin) && 'mb-5'} d-none d-sm-flex main`}
    >
      <Container>
        <Navbar.Brand href="#home">
          <img
            src="https://react-bootstrap.github.io/logo.svg"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt={Constants.SITE_NAME}
          />
          {Constants.SITE_NAME}
          {' '}
          <span className="brand-version">{Constants.SITE_VERSION}</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse>
          <Nav>
            <Link className="nav-link" to="/">
              Inicio
            </Link>
            <Link className="nav-link" to="/librerias">
              Librerías
            </Link>
            <Link className="nav-link" to="/resultados">
              Resultados
            </Link>
          </Nav>
        </Navbar.Collapse>

        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Navbar.Brand href="#home">
              <img
                src={user?.image ? `${process.env.REACT_APP_AVATARS_URL}/${user.image}` : DefaultAvatar}
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <NavDropdown title={user?.name || user?.username} id="collasible-nav-dropdown">
              { /* <Link to="/perfil" className="dropdown-item">
                <FontAwesomeIcon icon={faUser} />
                {' '}
                Mi perfil
              </Link>
              <NavDropdown.Divider />
              */ }
              <Link to="/logout" className="dropdown-item">
                <FontAwesomeIcon icon={faPowerOff} />
                {' '}
                Cerrar sesión
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    <Navbar
      fixed="bottom"
      className="d-flex d-sm-none justify-content-between mobile-nav"
      variant="light"
    >
      <NavLink activeClassName="nav-link-active" className="nav-link text-center" to="/">
        <div>
          <FontAwesomeIcon icon={faDotCircle} />
        </div>
        Para tí
      </NavLink>
      <NavLink activeClassName="nav-link-active" className="nav-link text-center" to="/librerias">
        <div>
          <FontAwesomeIcon icon={faList} />
        </div>
        Librerías
      </NavLink>
      <NavLink activeClassName="nav-link-active" className="nav-link text-center" to="/test/nuevo">
        <div>
          <FontAwesomeIcon icon={faPlay} />
        </div>
        Play
      </NavLink>
      <NavLink activeClassName="nav-link-active" className="nav-link text-center" to="/resultados">
        <div>
          <FontAwesomeIcon icon={faChartLine} />
        </div>
        Mio
      </NavLink>
      <NavLink activeClassName="nav-link-active" className="nav-link text-center" to="/resultados">
        <div>
          <FontAwesomeIcon icon={faEllipsisH} />
        </div>
        Más
      </NavLink>
    </Navbar>
    {
      (submenu) && <NavbarSubComponent links={submenu.links} buttons={submenu.buttons} />
    }
  </div>
)
}
export default MainMenu;
