import TTest, { TTConfig } from 'packages/_core/types/TTest';
import { AppThunk } from '../thunk.type';
import { addTest, setActiveTest, setTests } from '../actions';
import { ResponseTest } from '../types/server-types';

export const thunkCreateTest = (testConfig: TTConfig): AppThunk<Promise<TTest>> => async (
  dispatch,
  getState,
  api,
) => api.post<ResponseTest>('/test', testConfig).then(
  (response) => {
    dispatch(addTest(response.data.data));
    dispatch(setActiveTest(response.data.data.id));
    return response.data.data;
  },
)

export const thunkRetrieveActiveTest = (id: number): AppThunk => async (
  dispatch,
  getState,
  api,
) => {
  const response = await api.get(`/test/${id}`)
  if (response && response.status === 200) {
    dispatch(setTests([response.data.data]));
    dispatch(setActiveTest(response.data.data.id));
  }
}
