import React from 'react';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

// Progress libs
// import LibraryProgress from './LibraryProgress';

import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import TLibrary from 'packages/_core/types/TLibrary';
// import 'components/feed-cards/node_modules/react-circular-progressbar/dist/styles.css';

import './library-card.scoped.scss';

const LibraryCard = ({ id, stats, ...props }: TLibrary) => {
  // Test
  const percent = Math.round((stats.success / stats.total) * 100);
    return (
      <Link
        to={`/librerias/${id}`}
        className="card"
      >
        <Card.Body>
          <Row>
            <Col xs={3} sm={2} className="pr-0 FeedProgressBarContainer">
              <CircularProgressbarWithChildren
                backgroundPadding={8}
                value={percent || 0}
                styles={buildStyles({
                  pathColor: '#FF8C8C',
                  trailColor: '#f3f3f3',
                })}
              >
                <div style={{
                  fontSize: 12,
                  marginTop: 0,
                  lineHeight: '0.7rem',
                  textAlign: 'center',
                }}
                >
                  <span style={{ fontSize: '1.4em' }}>
                    {
                      percent || 0
                    }
                  </span>
                  <span style={{ fontSize: '0.8em' }}>
                    %
                  </span>
                  <br />
                  <span style={{ fontSize: '0.8em' }}>
                    aciertos
                  </span>
                </div>
              </CircularProgressbarWithChildren>
            </Col>
            <Col xs={9} md={9}>
              <h5 className="pt-2 mb-0">{props.name}</h5>
              <span>{props.description}</span>
            </Col>
          </Row>
        </Card.Body>
      </Link>
    );
}

export default LibraryCard;
