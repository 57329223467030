import React, { useEffect, useState } from 'react';

import { LibraryDispatch } from 'packages/_core/library/thunk.type';

// Bootstrap
import { motion } from 'framer-motion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Font awesome
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { useDispatch, useSelector } from 'react-redux';
import { thunkGetLibraries } from 'packages/_core/library/thunks/library.thunk';
import { RootState } from 'packages/_core/app.store';
import LoadingPage from 'packages/FreeUserApp/containers/loading/loading.page';
import Wizzard from '../wizzard/wizzard.component';
import LibrarySelectionItem from './library-item.component';

import './library-factory.scoped.scss';
import NoEligibleLibraries from '../no-eligible-libraries/no-eligible-libraries';

type props = {
  submitCallback: (selected: number[]) => void;
  backCallback: () => void;
  initial: number[];
};

// Input Docs: https://react-circular-input.now.sh/examples#render-prop
const LibrarySelectionContainer = (props: props) => {
  const { libraries } = useSelector((state: RootState) => state);
  const [searchString, setSearchString] = useState('');
  const [selected, setSelected] = useState<number[]>([]);
  const dispatch: LibraryDispatch = useDispatch();

  // Initially load user libraries
  useEffect(() => {
    dispatch(thunkGetLibraries())
  }, []);

  // Set default values if any
  useEffect(() => {
    setSelected(props.initial);
  }, [props.initial])

  /* callback when the filter text input changes, to trigger display of coincidences */
  const onFilterChange = (e: any) => {
    const searchFiltered = e.target.value
    .replace(/^\s+/, '')
    .replace(/\s+$/, '')
    .toLowerCase();
    setSearchString(searchFiltered)
    console.log('search term', searchFiltered, e)
  };

  const isSelected = (id: number) => selected.findIndex((ele) => ele === id);

  const libraryClicked = (id: number) => {
    const found = isSelected(id);

    if (found < 0) {
      const tmp = [...selected];
      tmp.push(id);
      setSelected(tmp);
    } else {
      const tmp = [...selected];
      tmp.splice(found, 1);
      setSelected(tmp);
    }
  }

  // User never used the app. no libraries nor tests done
  if (libraries.libraries.length === 0 && libraries.loading === false && libraries.updated) {
    return (
      <Wizzard
        buttonEnabled={(selected.length > 0)}
        buttonIcon={faArrowRight}
        title="Elige los temas"
        buttonText={`Siguiente (${selected.length})`}
        buttonOnClick={() => props.submitCallback(selected)}
        buttonBack={() => props.backCallback()}
      >
        <Container className="main-container-padded">
          <Row className="mb-3">
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <NoEligibleLibraries />
            </Col>
          </Row>
        </Container>
      </Wizzard>
    )
  }

  // No tests nor libraries, and loading. Wait for response before showing anything
  if (libraries.libraries.length === 0 && (libraries.loading || !libraries.updated)) {
    return (
      <Wizzard
        buttonEnabled={(selected.length > 0)}
        buttonIcon={faArrowRight}
        title="Elige los temas"
        buttonText={`Siguiente (${selected.length})`}
        buttonOnClick={() => props.submitCallback(selected)}
        buttonBack={() => props.backCallback()}
      >
        <Container className="main-container-padded">
          <Row className="mb-3">
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <LoadingPage />
            </Col>
          </Row>
        </Container>
      </Wizzard>
    );
  }

  return (
    <Wizzard
      buttonEnabled={(selected.length > 0)}
      buttonIcon={faArrowRight}
      title="Elige los temas"
      buttonText={`Siguiente (${selected.length})`}
      buttonOnClick={() => props.submitCallback(selected)}
      buttonBack={() => props.backCallback()}
    >
      <Container>
        <Row>
          <Container>
            {
              (libraries.libraries.length !== 0) && (
              <Row className="mb-3">
                <Col
                  className=""
                  xs={{ span: 12, offset: 0 }}
                  md={{ span: 8, offset: 2 }}
                >
                  <input
                    className="topSearch form-control"
                    onChange={(e) => onFilterChange(e)}
                    type="text"
                    placeholder="Buscar librerias..."
                    autoComplete="off"
                  />
                </Col>
              </Row>
              )
            }
            <div className="row">
              <Col xs={{ span: 12, offset: 0 }} md={{ span: 8, offset: 2 }}>
                <motion.div animate="show">
                  {libraries.libraries.map((elem) => {
                    if (elem.name?.toLowerCase().includes(searchString) && elem.questions.length > 0) {
                      // decide wether to hide or show this component based on the filter (BUT DON'T DESTROY THE COMPONENT TO KEEP IT'S TATUS DATA!)
                      return (
                        <LibrarySelectionItem
                          {...elem}
                          checked={!((isSelected(elem.id) < 0))}
                          clicked={(id) => libraryClicked(id)}
                        />
                      );
                    }
                    return <></>;
                  })}
                </motion.div>
              </Col>
            </div>
          </Container>
        </Row>
      </Container>
    </Wizzard>
  );
}
export default LibrarySelectionContainer;
