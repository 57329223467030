import TLibrary from '../types/TLibrary';
import { TTConfig, TType } from '../types/TTest';

export class TestConfig implements TTConfig {
    libraries: number[] | TLibrary[];

    repeatQuestions: boolean;

    showLibraries: boolean;

    randomizedLibraries: boolean;

    randomizedQuestions: boolean;

    questionsPerLibrary: number;

    type: TType;

    constructor(params?: TestConfig) {
        this.libraries = params?.libraries || [];
        this.repeatQuestions = params?.repeatQuestions || true;
        this.showLibraries = params?.showLibraries || false;
        this.randomizedLibraries = params?.randomizedLibraries || true;
        this.questionsPerLibrary = params?.questionsPerLibrary || 10;
        this.randomizedQuestions = params?.randomizedQuestions || true;
        this.type = params?.type || TType.test
    }
}
