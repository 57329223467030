import React, { useEffect, useState } from 'react';

const FeedbackIcons = ({ correct }: {correct: boolean | undefined}) => {
    if (correct === undefined) return null;
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [show, setShow] = useState(true);

    useEffect(() => {
      if (!correct) {
        setTimer(setTimeout(() => {
          setShow(false);
        }, 1100))
      }
      return () => {
        if (timer !== null) clearTimeout(timer);
      }
    }, [correct])

    if (!show) return null;

    if (correct) {
        return (
          <div className="swal2-icon swal2-success swal2-icon-show" style={{ display: 'flex', zIndex: 1000 }}>
            <div className="swal2-success-circular-line-left" style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
            <span className="swal2-success-line-tip" />
            {' '}
            <span className="swal2-success-line-long" />
            <div className="swal2-success-ring" />
            {' '}
            <div className="swal2-success-fix" style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
            <div className="swal2-success-circular-line-right" style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
          </div>
        );
    }
        return (
          <div className="swal2-icon swal2-error swal2-icon-show" style={{ display: 'flex', zIndex: 1000 }}>
            <span className="swal2-x-mark">
              <span className="swal2-x-mark-line-left" />
              <span className="swal2-x-mark-line-right" />
            </span>
          </div>
        );
}

export default FeedbackIcons;
