import React from 'react';

import './library-stats.scoped.scss';
import {
 Card, Col, Row,
} from 'react-bootstrap';
import TLibrary from 'packages/_core/types/TLibrary';

export const LibraryStats = ({ stats }: TLibrary) => {
  const fail = Math.round((stats.fail / stats.total) * 100);
  const success = Math.round((stats.success / stats.total) * 100);
  const empty = Math.round((stats.empty / stats.total) * 100);
  return (
    <>
      <Row className="">
        <Col xs={4} className="pr-1 pr-sm-3">
          <Card className="mb-3 progress-card">
            <Card.Body className="d-flex flex-wrap p-2 p-sm-3 align-items-baseline">
              <div className="number">
                {success || 0}
                <span>
                  %
                </span>
              </div>
              <div className="text">
                Aciertos
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4} className="pl-1 pr-1 pl-sm-3 pr-sm-3 ">
          <Card className="mb-3 progress-card">
            <Card.Body className="d-flex flex-wrap p-2 p-sm-3 align-items-baseline">
              <div className="number" style={{ color: '#467F8E' }}>
                {fail || 0}
                <span>
                  %
                </span>
              </div>
              <div className="text">
                Fallos
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4} className="pl-1 pl-sm-3">
          <Card className="mb-3 progress-card">
            <Card.Body className="d-flex flex-wrap p-2 p-sm-3 align-items-baseline">
              <div className="number">
                {empty || 0}
                <span>
                  %
                </span>
              </div>
              <div className="text">
                Omision
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default LibraryStats;
