import TLibrary from './TLibrary';
import TQuestion, { TResponse } from './TQuestion';
import TSimpleStats from './TSimpleStats';

export interface TTest {
  id: number;
  owner: number;
  parent?: number | null;
  start: Date | string | null;
  end?: Date | string | null;
  creation: Date;
  content: TTContent[];
  name?: string,
  historic?: TSHistoric[],
  config: TTConfig,
  stats: TSimpleStats,
  allowRetry?: boolean, // Used in play.hoc.tsx to allow player to stay in results page
};
export default TTest;

/**
 * Test type
 */
export enum TType {
  test = 0,
  practice = 1,
  game = 2,
}
export interface TTConfig {
  libraries: Array<number> | Array<TLibrary>;
  repeatQuestions: boolean;
  showLibraries: boolean;
  randomizedLibraries: boolean;
  randomizedQuestions: boolean;
  questionsPerLibrary: number;
  type: TType;
}

export type GAME_TYPE = 'SURVIVAL' | 'SPEED';
export interface TTGameConfig extends TTConfig {
  game: GAME_TYPE
}

export interface TTContent {
  id: number;
  test?: TTest;
  question: TQuestion;
  answer?: TResponse | null; // answer response id
  answerDate: Date | null;
  library?: TLibrary;
  // unique?: boolean; // Is the question of this content the first time that appears?
}

/**
 * This is a resumed version of the TTest type, used for stats
 * related to a specific test.
 */
export interface TSHistoric {
  fail: number;
  success: number;
  empty: number;
  testId: number;
  date: Date | string | null;
}
