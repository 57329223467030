import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faWifi,
} from '@fortawesome/free-solid-svg-icons';

// Show small game and keep retying the action in the background...

const NetworkConnectionError = () => (
  <div className="text-center mb-6">
    Oops, parece que no tienes conexión
    <div className="d-flex justify-content-center mt-4 mb-2">
      <FontAwesomeIcon icon={faWifi} size="2x" />
    </div>
    Comprueba tu conexión
  </div>
)
export default NetworkConnectionError;
