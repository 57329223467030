import TUser from 'packages/_core/types/TUser';

export const userStorage = {
    getUser: () => {
        const tmp = localStorage.getItem('user');
        if (tmp && typeof tmp === 'string') {
            return JSON.parse(tmp) as TUser
        }
        return null;
    },
    hasData: () => (!!localStorage.getItem('user')),
    saveData: (data: TUser) => localStorage.setItem('user', JSON.stringify(data)),
    remove: () => localStorage.removeItem('user'),
    getSession: () => localStorage.getItem('session'),
    setSession: (newSession: string) => localStorage.setItem('session', newSession),
    clearSession: () => localStorage.removeItem('session'),
}
