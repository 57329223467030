import {
  combineReducers, createStore, applyMiddleware, compose,
} from 'redux';
import tests from 'packages/_core/tests/reducer';
import libraries from 'packages/_core/library/reducer';
import error from 'packages/_core/error/reducer'
import user from 'packages/_core/user/reducer'
import thunk from 'redux-thunk';
import api from './services/api.service';

export const rootReducer = combineReducers({
  tests,
  libraries,
  error,
  user,
});

export type RootState = ReturnType<typeof rootReducer>;

const composeEnhancers = (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose || thunk.withExtraArgument(api);

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk.withExtraArgument(api)),
  ));
export default store;
