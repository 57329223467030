import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import './action-buttons.scoped.scss';

interface Tprops {
  nextHandler: () => void;
  skipHandler: () => void;
  answerChoosen: boolean;
  isLastQuestion: boolean;
  blockSkip: boolean;
}

const ActionButtons = (props: Tprops) => (
  <Navbar fixed="bottom" className="gradient">
    <Container className="pb-3 buttons-container d-flex justify-content-between">
      <Button
        size="lg"
        variant="link"
        onClick={props.skipHandler}
        disabled={props.blockSkip}
      >
        {props.isLastQuestion ? 'Saltar y terminar' : 'Saltar pregunta'}
      </Button>
      <Button
        size="lg"
        variant={!props.answerChoosen ? 'light' : 'success'}
        onClick={props.nextHandler}
        disabled={!props.answerChoosen}
      >
        {props.isLastQuestion ? 'Terminar' : 'Siguiente'}
      </Button>
    </Container>
  </Navbar>
);
export default ActionButtons;
