import TResponse from '../../types/TResponse';
import TTest from '../../types/TTest';

export const SET_TESTS = 'SET_TESTS';
export const ADD_TEST = 'ADD_TEST';
export const UPDATE_TEST = 'UPDATE_TEST';
export const SET_LOADING_TESTS = 'SET_LOADING_TESTS';

export type TestHistoryStore = {
  active: number | null;
  list: Array<TTest>;
  updated: Date | null;
  loading: boolean;
};

interface SetTests {
  type: typeof SET_TESTS;
  payload: Array<TTest>;
}

interface AddTest {
  type: typeof ADD_TEST;
  payload: TTest;
}

interface UpdateTest {
  type: typeof UPDATE_TEST;
  payload: TTest;
}

interface SetLoadingTests {
  type: typeof SET_LOADING_TESTS;
  payload: boolean;
}

/**
 * Active test
 */

export const SET_ACTIVE_TEST = 'SET_ACTIVE_TEST';

interface SetActiveTest {
  type: typeof SET_ACTIVE_TEST;
  payload: number | null;
}

export type ActionTypes =
  | SetTests
  | AddTest
  | UpdateTest
  | SetLoadingTests
  | SetActiveTest;
