import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { shuffle } from 'lodash';
import './loader.scss';

interface LoaderProps {
  draggable: boolean;
  delay?: number;
}
// Input Docs: https://react-circular-input.now.sh/examples#render-prop
export const Loader: React.FC<LoaderProps> = ({ draggable, delay }) => {
  const [show, setShow] = useState(!(delay));

  useEffect(() => {
    if (delay) {
      const handler = setTimeout(() => setShow(true), delay)
      return () => {
        clearTimeout(handler);
      };
    }
    return undefined;
  }, [])

  if (!show) return null;

  return (
    <>
      <div className="circle-loader-container">
        <motion.div
          animate={{
            scale: [1, 1.3, 1.3, 1, 1],
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ['20%', '20%', '50%', '50%', '20%'],
          }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            times: [0, 0.2, 0.5, 0.8, 1],
            loop: Infinity,
            repeatDelay: 1,
          }}
          drag={draggable}
          dragConstraints={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      </div>
    </>
  )
}
Loader.defaultProps = { draggable: true };

// Special loader (random boxes)
// lodash library missing
const spring = {
  type: 'spring',
  damping: 20,
  stiffness: 300,
};

const initialColors = ['#FF008C', '#D309E1', '#9C1AFF', '#7700FF'];

export function BoxLoader() {
  const [colors, setColors] = useState(initialColors);

  useEffect(() => {
    console.log('update');
    const timer = setTimeout(() => setColors(shuffle(colors)), 900);
    return () => clearTimeout(timer);
  }, [colors]);

  return (
    <ul className="boxloader">
      {colors.map((background) => (
        <motion.li
          key={background}
          layoutTransition={spring}
          style={{ background }}
        />
      ))}
    </ul>
  );
}
