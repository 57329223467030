import React, { useEffect, useState } from 'react';

import { Loader } from 'packages/FreeUserApp/shared/loaders/windows-loader.component';
import './loading.scoped.scss';

const showAfter = 1200; // ms
/**
 * Shows a full page loader screen
 */
const LoadingPage = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const handler = setTimeout(() => {
            setShow(true);
        }, showAfter)
        return () => {
            clearTimeout(handler);
        };
    }, [])

    return (
      <div style={{ opacity: (show) ? 1 : 0 }} className="loading-container d-flex flex-column justify-content-center h-50 text-center">
        <h4>Cargando</h4>
        <p style={{ marginBottom: '2rem' }}>Esto no suele tardar tanto...</p>
        <Loader draggable />
      </div>
    )
}
export default LoadingPage;
