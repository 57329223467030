import { faInfo, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './card-base.scoped.scss'

const CardBaseComponent = ({ link, ...props }:
    React.PropsWithChildren<{
        link: string,
        icon?: React.ReactNode,
        type?: string,
        disabled?: boolean
    }>) => (
      <Link to={(!props.disabled) ? link : '/'} className={`card card-base ${(props.disabled) && 'disabled'}`}>
        <Card.Body className="d-flex justify-content-between">
          {
            (props.icon) && (
              <div className={`icon-box ${props.type}`}>
                {props.icon}
              </div>
            )
          }
          <div className="w-100">
            {props.children}
          </div>
        </Card.Body>
      </Link>
    )

export default CardBaseComponent;
