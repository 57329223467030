import React, { useEffect, useState } from 'react';
import api from 'packages/_core/services/api.service';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { AnimatePresence } from 'framer-motion';
import TTest from 'packages/_core/types/TTest';
import { useDispatch } from 'react-redux';
import { setActiveTest, updateTest } from 'packages/_core/tests/actions';
import { TestDispatch } from 'packages/_core/tests/thunk.type';
import { thunkPatchAnswer } from 'packages/_core/tests/thunks/content.thunk';

import { Howl, Howler } from 'howler';
import select from 'packages/FreeUserApp/assets/sfx/select-item.wav';
import success from 'packages/FreeUserApp/assets/sfx/question-success.wav';
import fail from 'packages/FreeUserApp/assets/sfx/question-fail.wav';
import skip from 'packages/FreeUserApp/assets/sfx/question-skip.wav';

import { Test } from 'packages/_core/models/Test';
import ActionButtons from '../components/action-buttons/action-buttons.component';
import TestQuestionCard from '../components/question-card/question-card.component';
import ProgressHeader from '../components/progress-header/progress-header.component';
import PlayDefaultResult from './default-results.page';

import './default-play.scoped.scss';

/*
const sound = new Howl({
  build: ['packages/FreeUserApp/assets/sfx/select-item.wav'],
  sprite: {
    blast: [0, 3000],
    laser: [4000, 1000],
    winner: [6000, 5000],
  },
}); */

const PlayDefault = (test: TTest) => {
  const [contentIndex, setContentIndex] = useState(0); // this sets the current content shown in the windows, it gets delayed to show feedback animations
  const [lastContent, setLastContent] = useState(-1);

  const [totalQuestions, setTotalQuestions] = useState(test.stats.total) // Used to keep track of total unanswered questions when reset wrong ones
  const [attempt, setAttempt] = useState(1); // Current attempt for this test

  const [progressIndex, setProgressIndex] = useState(0); // This progress is used to move the progress bar, without delay
  const [answer, setAnswer] = useState<number | null>(null);
  const [audio] = useState({
    click: new Audio(select),
    success: new Audio(success),
    fail: new Audio(fail),
    skip: new Audio(skip),
  })

  const dispatch: TestDispatch = useDispatch()

  /**
   * Load the audio feedback list to have it
   * in memory beforehand
   */
  useEffect(() => {
    audio.click.load();
    audio.success.load();
    audio.fail.load();
    audio.skip.load();

    // Check if the test was already started in a previous attempt and init it properly
    const initialIndex = Test.getNextContent(test);
    if (initialIndex.index !== 0) {
      setContentIndex(initialIndex.index);
      setProgressIndex(initialIndex.index);
    }
  }, [])

  const saveAnswer = (skip?: boolean) => {
    if (contentIndex === lastContent) return
    setProgressIndex(progressIndex + 1);

    const { success } = test.stats;
    const newTest = Test.setAnswer(test, test.content[contentIndex].id, (skip) ? null : answer)

    // Save answer to server
    const tmp = newTest.content[contentIndex];
    api.patch<undefined>(`/test/content/${tmp.id}`, tmp);

    if (Test.getNextContent(test).index === -1) {
      // If it is the last question, finish test
      dispatch(updateTest({
        ...newTest,
        ...Test.addHistoryData(newTest, newTest.stats),
        end: new Date(),
        allowRetry: true,
      }));
      dispatch(setActiveTest(null));
      // Call server to finish test. Don't update
      api.patch<undefined>(`/test/${test.id}`, null);
    } else {
      dispatch(updateTest({ ...newTest }));
    }

    setLastContent(contentIndex);

    if (!answer) return;
    if (success !== newTest.stats.success) {
      audio.success.play();
      loadNext(true);
      // sound.play('blast')
    } else {
      audio.fail.play();
      // Dont go to next question, allow user to read the right answer before continue
    }
  }

  /**
   * Sets answer for the current question.
   * @param id anser id
   */
  const chooseAnswer = (id: number) => {
    audio.click.play();
    setAnswer(id);
  }

  /**
   * Confirm answer and see result
   */
  const evaluateAnswer = () => {
    // Play feedback
    saveAnswer();
  };

  /**
   * User read the correct answer and wants to continue to the next one
   */
  const failConfirmation = () => {
    audio.skip.play();
    loadNext(false);
  }

  /**
   * Loads the next question
   * @param delay true to delay the load of the next question, false to instantly load next
   */
  const loadNext = (delay?: boolean) => {
    if (delay !== undefined && delay === true) {
      setTimeout(() => {
        setContentIndex(Test.getNextContent(test).index);
        setAnswer(null);
      }, 1500)
    } else { // "false" Used when skipping questions
      setContentIndex(Test.getNextContent(test).index);
      setAnswer(null);
    }
  };

  /**
   * User wants to skip this question
   */
  const skipAnswer = () => {
    audio.skip.play();
    // setProgressIndex(progressIndex + 1);
    setAnswer(null);
    saveAnswer(true);
    loadNext();
  };

  /**
   * Reset current test to repeat only the failed
   * questions.
   */
  const resetTest = () => {
    setAttempt(attempt + 1);
    setProgressIndex(0);
    setLastContent(-1)
    dispatch(updateTest(Test.resetFailedAnswers(test)));
    setTotalQuestions(test.stats.total - test.stats.success);
    const newIdx = Test.getNextContent(test).index;
    setContentIndex(newIdx);
  }

  if (!test.content[contentIndex]) {
    return <PlayDefaultResult attempt={attempt} repeat={resetTest} test={test} />
  }

  return (
    <>
      <ProgressHeader
        numberQuestions={totalQuestions}
        currentQuestionKey={progressIndex}
        answer={test.content[contentIndex].answer}
        testId={test.id}
      />
      <Container className="pb-5">
        <Row>
          <Col sm={12}>
            <AnimatePresence exitBeforeEnter={false} initial={false}>
              <TestQuestionCard
                answerCallback={chooseAnswer}
                direction={1}
                {...test.content[contentIndex]}
                answerId={answer}
              />
            </AnimatePresence>
          </Col>
        </Row>
      </Container>
      <ActionButtons
        nextHandler={(test.content[contentIndex].answer?.correct === false) ? failConfirmation : evaluateAnswer}
        skipHandler={skipAnswer}
        answerChoosen={!!answer}
        isLastQuestion={contentIndex === test.content.length - 1}
        blockSkip={!!(test.content[contentIndex].answer)}
      />
    </>
  )
}
export default PlayDefault;
