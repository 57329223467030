import TLibrary from 'packages/_core/types/TLibrary';
import { LQuestion } from 'packages/_core/types/TQuestion';
import { AppThunk } from '../thunk.type';
import {
 addLibrary, addQuestion, setLibraries, setLoadingLibraries, updateLibrary,
} from '../actions';
import { ResponseLibraries } from '../types';
import { ResponseLibrary, ResponseQuestion } from '../types/response-types';
import { Library } from '../../models/Library';
import { NewLibrary } from '../types/request-types';

export const thunkGetLibraries = (): AppThunk<Promise<TLibrary[]>> => async (
  dispatch,
  getState,
  api,
) => api.get<ResponseLibraries>('/user/libraries').then((response) => { // this any should be the type of response from server...
  dispatch(setLibraries(response.data.data as Array<TLibrary>))
  return []
});

export const thunkRetrieveLibrary = (id: number): AppThunk => async (
  dispatch,
  getState,
  api,
) => {
  dispatch(setLoadingLibraries(true));
  return api.get<ResponseLibrary>(`/library/${id}`).then(
    (response) => {
      if (Library.findById(getState().libraries.libraries, response.data.data.id)) {
        dispatch(updateLibrary(response.data.data));
      } else {
        dispatch(addLibrary(response.data.data));
      }
      return response.data.data;
    },
  ).catch((e) => e);
}

export const thunkCreateLibrary = (library: NewLibrary): AppThunk => async (
  dispatch,
  getState,
  api,
) => api.post<ResponseLibrary>('/library', library).then(
  ({ data }) => {
    dispatch(addLibrary(data.data)); // add library to store
    return Promise.resolve(data.data);
  },
  (err) => err,
).catch((e) => e)
