import React, { useEffect } from 'react';

import { motion } from 'framer-motion';

import LibrayCard from 'packages/FreeUserApp/containers/library/list/components/library-card/library-card.component';
import { RootState } from 'packages/_core/app.store';
import { useDispatch, useSelector } from 'react-redux';
import { thunkGetLibraries } from 'packages/_core/library/thunks/library.thunk';
import { LibraryDispatch } from 'packages/_core/library/thunk.type';
import TLibrary from 'packages/_core/types/TLibrary';
import NoLibrariesFound from './components/no-libraries-found/no-libraries-found.component';

const variants = {
  show: {
    transition: {
      staggerChildren: 0.12,
      delayChildren: 0.1,
    },
  },
};

const LibrariesFactory = ({ libraries }: { libraries: TLibrary[] }) => (
  <motion.div variants={variants} animate="show">
    {
        libraries.map((library) => (
          <LibrayCard {...library} />
        ))
    }
  </motion.div>
  )
export default LibrariesFactory;
