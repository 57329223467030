import { userStorage } from 'packages/FreeUserApp/services/user.service';
import TUser from 'packages/_core/types/TUser';
import { setUser } from '../actions';
import { AppThunk } from '../thunk.type';
import { ResponseUser } from '../types/response-types';

export const thunkLogout = (): AppThunk<Promise<TUser | ResponseUser>> => async (
    dispatch,
    getState,
    api,
  ) => {
    api.defaults.withCredentials = true;
    return api.post<ResponseUser>(
        '/user/logout',
        { withCredentials: true },
        ).then(
            ({ data }) => {
                if (data.data) {
                    userStorage.remove();
                    userStorage.clearSession();
                    dispatch(setUser(null))
                    return data.data;
                }
            return data;
            },
            (err) => Promise.reject(err),
      ).catch((e) => Promise.reject(e))
  }
