import Cookies from 'js-cookie';
import { userStorage } from 'packages/FreeUserApp/services/user.service';
import { RootState } from 'packages/_core/app.store';
import { setUser } from 'packages/_core/user/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ children, ...rest }: any) => {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch();

    try {
      if (process.env.REACT_APP_COOKIENAME && !Cookies.get(process.env.REACT_APP_COOKIENAME)) {
        throw new Error('Not logged in');
      }

      // If no user in state, look if present in localstorage
      if (!user) {
        const local = userStorage.getUser();
        if (local) {
          dispatch(setUser(local))
        } else {
          throw new Error('Not logged in');
        }
      }
    } catch (e) {
      return (
        <Route
          {...rest}
          render={() => <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />}
        />
      )
    }

    return (
      <Route
        {...rest}
        render={() => children}
      />
    )
}

export default ProtectedRoute;
