import TTest from 'packages/_core/types/TTest';
import { TestHistoryStore } from '../types/store-types';

/**
 * Updates a test from the historic given the test with the new data
 * @param state Current store state
 * @param newTest New test data to update the store
 */
export const updateOneReducer = (state: TestHistoryStore, newTest: TTest): TestHistoryStore => {
    let found = false;
    const updatedHistoric = state.list.map((test) => {
        if (test.id !== newTest.id) return test;
        found = true;
        return { ...test, ...newTest };
    })

    if (found) return { ...state, list: [...updatedHistoric] }
    return state;
}
