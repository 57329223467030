import React, { useEffect } from 'react';

import { RootState } from 'packages/_core/app.store';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { thunkGetTests } from 'packages/_core/tests/thunks/tests.thunk';
import { TestDispatch } from 'packages/_core/tests/thunk.type';
import TLibrary from 'packages/_core/types/TLibrary';
import TestCard from '../test-card/test-card.component';
import { FilterLibraries } from '../library-filter/filter.types';
import NoTestsFound from '../no-tests-found/no-tests-found.component';

const variants = {
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.12,
        delayChildren: 0.05,
        duration: 0.05,
      },
    },
    hide: {
        opacity: 0,
    },
  };

export const TestFactory = (props: {
    filter: {
        libraries: number[]
    }
}) => {
    const { tests, libraries } = useSelector((state: RootState) => state);

    const hasLibrary = (testLibraries: TLibrary[]) => {
        if (props.filter.libraries.length === 0) return true; // If no libs selected, show all.

        const foundAny = testLibraries.find((lib) => (
            props.filter.libraries.includes(lib.id)))
        if (foundAny) return true;
        return false;
    }

    if (tests.list.length > 0) {
      return (
        <motion.div variants={variants} animate={(tests.list.length > 0) ? 'show' : 'hide'}>
          {tests.list.map((test) => (hasLibrary(test.config.libraries as TLibrary[])) && (
            <TestCard key={`test_${test.id}`} {...test} />
          ))}
        </motion.div>
      );
    }

    return (
      <NoTestsFound libraries={libraries.libraries} />
    );
}
export default TestFactory;
