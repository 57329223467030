import React, { useEffect, useState } from 'react';

import { connect, ConnectedProps, useSelector } from 'react-redux';

// Bootstrap
import { motion } from 'framer-motion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import ListGroup from 'react-bootstrap/ListGroup';

// Font awesome
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { thunkCreateTest } from 'packages/_core/tests/thunks/active.thunk';
// import { TTConfig } from "packages/_core/types/TTest";
import { TestHistoryStore } from 'packages/_core/tests/types/store-types';
// import store from "packages/FreeUserApp/store/app.store";
import './settings-form.scoped.scss'
import { TTConfig } from 'packages/_core/types/TTest';
import { TestConfig } from 'packages/_core/models/TestConfig';
import TLibrary from 'packages/_core/types/TLibrary';
import { RootState } from 'packages/_core/app.store';
import { Alert } from 'react-bootstrap';
import TTestCreationParams from '../../types/TCreation';

import Wizzard from '../wizzard/wizzard.component';

type props = {
  submitCallback: () => void,
  backCallback: () => void,
  updateConfig: (config: TTestCreationParams) => void,
  config: TTConfig,
};

interface Warning {
  unavailable: number,
  libraryNames: string[],
}

// Input Docs: https://react-circular-input.now.sh/examples#render-prop
const NewTestOptions = (props: props) => {
  const { libraries } = useSelector((state: RootState) => state.libraries)
  const [warning, setWarning] = useState<Warning>({
    unavailable: 0,
    libraryNames: [],
  })
  const [libraryData, setLibraryData] = useState<TLibrary[]>([])
  const [questionNumber, setQuestionNumber] = useState(5)

  const rangeUpdated = (e: any) => {
    setQuestionNumber(Number(e.target.value))
    updateValidity(Number(e.target.value));
  };

  const updateValidity = (amount: number) => {
    const questionsPerLib = Math.round(amount / props.config.libraries.length);
    props.updateConfig({ questionsPerLibrary: questionsPerLib })
    const { unavailable, libraryNames } = getAvailableQuestions(questionsPerLib);
    console.log('checking validity', {
      amount, questionsPerLib, unavailable, libraryNames,
    })
    setWarning({
      unavailable,
      libraryNames,
    })
  }

  const getSelectedLibs = (ids: number[]) => {
    const map = ids.reduce((map: {[details: number] : number;}, element) => {
      map[element] = element;
      return map;
    }, {});

    const libs: TLibrary[] = [];
    let found = 0;
    let i = 0;
    while (i < libraries.length && found < ids.length) {
      if (map[libraries[i].id]) {
        libs.push(libraries[i]);
        ++found;
      }
      ++i;
    }
    return libs;
  }

  const getAvailableQuestions = (lengthPerLib: number): Warning => {
    let unavailable = 0; // Total amount of needed questions
    const libraryNames: string[] = []
    libraryData.forEach(lib => {
      const aval = lib.questions.length - lengthPerLib;
      if (aval < 0) {
        unavailable += aval;
        libraryNames.push(lib.name);
      }
      console.log('aval', aval)
    })
    console.log('aval', { libraryData })
    return {
      unavailable,
      libraryNames,
    };
  }

  useEffect(() => {
    setLibraryData(getSelectedLibs(props.config.libraries as number[]))
  }, [])

  useEffect(() => {
    updateValidity(questionNumber);
  }, [libraryData])

  return (
    <Wizzard
      buttonEnabled={(props.config.questionsPerLibrary * props.config.libraries.length) + warning.unavailable > 0}
      buttonIcon={faCheck}
      title="Personaliza el test"
      buttonText="Comenzar test"
      buttonOnClick={() => props.submitCallback()}
      buttonBack={() => props.backCallback()}
    >
      <Container className="mt-4">
        <Row>
          <Col>
            <ListGroup>
              <ListGroup.Item>
                <Form.Group controlId="formBasicRange">
                  <Form.Label>Numero de preguntas</Form.Label>
                  <Form.Text className="text-center">
                    <span style={{ fontSize: '1.75rem' }}>
                      {(props.config.questionsPerLibrary * props.config.libraries.length)}
                    </span>
                    <span className="ml-1" style={{ fontSize: '1rem' }}>preguntas</span>
                    <span className="ml-2">
                      (
                      {props.config.questionsPerLibrary}
                      {' '}
                      por librería)
                    </span>
                  </Form.Text>
                  <Form.Control
                    min={1}
                    max={Math.round(50 / props.config.libraries.length) * props.config.libraries.length}
                    value={questionNumber}
                    onChange={rangeUpdated}
                    type="range"
                  />
                  {
                      (questionNumber > 20) && (
                        <small className="text-muted">No recomendamos más de 20 preguntas por test</small>
                      )
                  }
                  {
                      (warning.libraryNames.length > 0) && (
                        <Alert variant="warning" className="mt-4">
                          <Alert.Heading>
                            Faltan preguntas en la
                            {(warning.libraryNames.length > 1) && 's'}
                            {' '}
                            librería
                            {(warning.libraryNames.length > 1) && 's'}
                            :
                          </Alert.Heading>
                          <ul>
                            {
                              warning.libraryNames.map(libName => (
                                <li>
                                  {libName}
                                </li>
                              ))
                            }
                          </ul>
                          <hr />
                          <p>
                            El test tendrá un total de
                            {' '}
                            <strong>
                              {(props.config.questionsPerLibrary * props.config.libraries.length) + warning.unavailable}
                              {' '}
                              preguntas
                            </strong>
                          </p>
                        </Alert>
                      )
                    }
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item>
                <Form.Group controlId="repeat-quesitons">
                  <Form.Check
                    type="switch"
                    id="repeat-quesitons"
                    label="Repetir preguntas (próximamente)"
                    disabled
                    onChange={(e: any) =>
                          props.updateConfig({ repeatQuestions: (e.target.value === 'on') })}
                    defaultChecked={props.config.repeatQuestions}
                  />
                  <Form.Text className="text-muted">
                    Activa esta opción si quieres que puedan aparecer
                    preguntas que ya te han aparecido en otros test
                  </Form.Text>
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item>
                <Form.Group controlId="show-library">
                  <Form.Check
                    type="switch"
                    id="show-library"
                    label="Mostrar librería (próximamente)"
                    disabled
                    onChange={(e: any) =>
                          props.updateConfig({ showLibraries: (e.target.value === 'on') })}
                    defaultChecked={props.config.showLibraries}
                  />
                  <Form.Text className="text-muted">
                    Activa esta opción si quieres ver a que librería pertenece cada pregunta
                  </Form.Text>
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item>
                <Form.Group controlId="groupby-libraries">
                  <Form.Check
                    type="switch"
                    id="groupby-libraries"
                    label="Agrupar por tema (próximamente)"
                    disabled
                    onChange={(e: any) =>
                      props.updateConfig({ randomizedLibraries: (e.target.value === 'on') })}
                    defaultChecked={props.config.randomizedLibraries}
                  />
                  <Form.Text className="text-muted">
                    Activa esta opción si quieres que las preguntas
                    aparezcan agrupadas por temas
                  </Form.Text>
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item>
                <Form.Group controlId="random-order">
                  <Form.Check
                    type="switch"
                    id="randomOrder"
                    label="Orden aleatorio (próximamente)"
                    disabled
                    onChange={(e: any) =>
                        props.updateConfig({ randomizedQuestions: (e.target.value === 'on') })}
                    defaultChecked={props.config.randomizedQuestions}
                  />
                  <Form.Text className="text-muted">
                    Activa esta opción si quieres que las preguntas se
                    mezclen de orden aleatoriamente
                  </Form.Text>
                </Form.Group>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </Wizzard>
  );
}
export default NewTestOptions;
