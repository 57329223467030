import React from 'react';
import { Link } from 'react-router-dom';

import TLibrary from 'packages/_core/types/TLibrary';

import bg from '../../../../../assets/illustrations/startup-rocket.svg'
import bg2 from '../../../../../assets/illustrations/balloon.svg'

import './no-libraries-found.scoped.scss';

const NoLibrariesFound = () => (
  <div className="container flex-column">
    <img className="bg-image" alt="No has hecho ningún test" src={bg} />
    <div className="mb-3 mt-5 text-center">
      <h4>¡No corras tanto!</h4>
      No puedes hacer tests si no tienes una librería con preguntas
    </div>
    <div>
      <Link className="btn btn-primary" to="/librerias/nueva">Crear una libraría ahora</Link>
    </div>
  </div>
  )

export default NoLibrariesFound;
