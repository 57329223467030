import TTest from 'packages/_core/types/TTest';
import { AppThunk } from '../thunk.type';
import {
 addTest, setActiveTest, setLoadingTests, setTests, updateTest,
} from '../actions';
import { findTestInList } from '../helper.fn';
import { ResponseTest, ResponseTestList } from '../types/server-types';

export const thunkGetTests = (state?: string, offset?: number): AppThunk => async (
  dispatch,
  getState,
  api,
) => {
    if (state && !offset) Promise.reject(new Error('Wrong params'));

    dispatch(setLoadingTests(true));
    const url = (state) ? `/user/tests/${state}/${offset}` : '/user/tests';
    return api.get<ResponseTestList>(url).then(
    (response) => {
      dispatch(setTests(response.data.data));
      return response.data.data;
    },
  );
}

export const thunkRetrieveTest = (id: number): AppThunk => async (
  dispatch,
  getState,
  api,
) => api.get<ResponseTest>(`/test/${id}`).then(
  (response) => {
    if (findTestInList(getState().tests.list, response.data.data.id)) {
      dispatch(updateTest(response.data.data));
    } else {
      dispatch(addTest(response.data.data));
    }
    return response.data.data;
  },
)

export const thunkUpdateTest = (test: TTest): AppThunk<Promise<TTest | undefined>> => async (
  dispatch,
  getState,
  api,
) => api.patch<ResponseTest>(`/test/${test.id}`, null).then(
    (response) => {
      dispatch(updateTest({ ...test, ...response.data.data }));
      dispatch(setActiveTest(null));
      return response.data.data;
    },
  ).catch((e) => findTestInList(getState().tests.list, test.id));
