import React from 'react';
import { TTContent } from 'packages/_core/types/TTest';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faTimes, faCheck, faBan,
} from '@fortawesome/free-solid-svg-icons';

import './content-resume.scoped.scss';

const Content = (props: { content: TTContent[] }) => {
  const getIcon = (cnt: TTContent) => {
    if (!cnt.answer) return faBan;
    if (cnt.answer.correct) return faCheck;
    return faTimes;
  }

  const getCorrectText = (cnt: TTContent) => cnt.question.answers.find((ans) => ans.correct)

return (
  <>
    {props.content.map((_content) => (
      <div className="content" key={_content.id}>
        <div className="question">
          <FontAwesomeIcon icon={getIcon(_content)} className="mr-2" />
          {_content.question.text}
        </div>
        {(_content.answer?.correct === false) && (
          <div className="answer">
            <span className="strikeout">
              {_content.answer?.text}
            </span>
          </div>
        )}
        <div className="answer">
          {getCorrectText(_content)?.text}
        </div>
      </div>
    ))}
  </>
)
}
export default Content;
