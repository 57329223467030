import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { TNavbarSubmenu } from '../app/components/navbar/types';

export const TEST_LIST: TNavbarSubmenu = {
    links: [
        {
            route: '/resultados',
            text: 'Resultados',
        },
    ],
}
