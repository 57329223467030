import React from 'react';

// Bootstrap
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

// Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import './wizzard.scoped.scss';
import { useHistory } from 'react-router-dom';

import '../../../../app/components/navbar/navbar.scoped.scss';

interface ptype {
  buttonEnabled: boolean;
  buttonIcon?: IconDefinition;
  buttonText: string;
  title: string;
  buttonOnClick: () => void;
  buttonBack: () => void;
  children: React.ReactNode;
}

const Wizzard: React.FC<ptype> = (props) => {
  const history = useHistory();

  return (
    <>
      <Navbar
        variant="light"
        sticky="top"
        expand="xl"
        className="subheader-nav mobile"
      >
        <Container>
          <Button variant="outline-primary" onClick={() => props.buttonBack()}>
            <FontAwesomeIcon className="mr-2" icon={faChevronLeft} />
            Volver
          </Button>
          <Navbar.Brand>{props.title}</Navbar.Brand>
          <Form inline>
            <Button
              variant={props.buttonEnabled ? 'success' : 'light'}
              disabled={!props.buttonEnabled}
              onClick={props.buttonOnClick}
              tabIndex={0}
            >
              {props.buttonText}
              {props.buttonEnabled && props.buttonIcon !== undefined && (
              <FontAwesomeIcon className="ml-2" icon={props.buttonIcon} />
            )}
            </Button>
          </Form>
        </Container>
      </Navbar>
      {props.children}
    </>
  );
}

export default Wizzard;
