import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';

import './main.scss';

// Redux
import { Provider, useSelector } from 'react-redux';
import { RootState } from 'packages/_core/app.store';
import store from '../../../_core/app.store';

import NavBar from './components/navbar/navbar.component';

// PAGES
import HomePage from '../home/home.page';
import LibraryPage from '../library/list/libraries.page';
import LibraryDetail from '../library/details/library-detail.page';
import TestDetail from '../test/details/details.hoc';
import LoginPage from '../login/login.page';
import ErrorHoc from '../error-pages/error.hoc';
import TestCreationHoc from '../test/creation/test-creation.hoc';
import TestListPage from '../test/list/test-list.page';
import PlayHoc from '../test/play/play.hoc';
import LibraryCreationPage from '../library/creation/library-creation.page';
import { LIBRARY_CREATION, LIBRARY_DETAIL, LIBRARY_LIST } from '../library/menu.definitions';
import { TEST_LIST } from '../test/menu.definitions';
import SignupPage from '../signup/signup.page';
import ProtectedRoute from './components/protected-route/protected-route.component';
import { LogoutPage } from '../logout/logout.page';

const AppHoc = () => {
  const { error, user } = useSelector((store: RootState) => store)

  if (error && error.fatal) {
    return (
      <Router>
        {(user) && <NavBar />}
        <ErrorHoc />
      </Router>
    );
  }

  return (
    <Router>
      <Switch>
        <ProtectedRoute exact path="/librerias">
          <NavBar submenu={LIBRARY_LIST} />
          <LibraryPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/librerias/nueva">
          <NavBar submenu={LIBRARY_CREATION} />
          <LibraryCreationPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/librerias/:id">
          <NavBar submenu={LIBRARY_DETAIL} />
          <LibraryDetail />
        </ProtectedRoute>
        <ProtectedRoute exact path="/librerias/:id/papelera">
          <NavBar submenu={LIBRARY_DETAIL} />
          <LibraryDetail />
        </ProtectedRoute>
        <ProtectedRoute exact path="/resultados">
          <NavBar submenu={TEST_LIST} />
          <TestListPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/test/nuevo/:type?">
          <NavBar disableMargin />
          <TestCreationHoc />
        </ProtectedRoute>
        <ProtectedRoute exact path="/test/:id/play">
          <PlayHoc />
        </ProtectedRoute>
        <ProtectedRoute exact path="/test/:id">
          <NavBar />
          <TestDetail />
        </ProtectedRoute>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/signup">
          <SignupPage />
        </Route>
        <Route exact path="/logout">
          <LogoutPage />
        </Route>
        <ProtectedRoute>
          <NavBar />
          <HomePage />
        </ProtectedRoute>
      </Switch>
    </Router>
  )
}
export default AppHoc;
