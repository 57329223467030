import React, { useEffect, useState } from 'react';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { motion } from 'framer-motion';
import TTest from 'packages/_core/types/TTest';
import { Button, Card } from 'react-bootstrap';

import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';

import './default-results.scoped.scss';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import Confetti from 'react-dom-confetti';
import { updateTest } from 'packages/_core/tests/actions';
import { useDispatch } from 'react-redux';

import end from 'packages/FreeUserApp/assets/sfx/test-end-percusion.wav';
import perfect from 'packages/FreeUserApp/assets/sfx/victory-yell.wav';

import { Test } from 'packages/_core/models/Test';
import TLibrary from 'packages/_core/types/TLibrary';
import TestHistoricStats from '../components/test-progression-stats/test-historic-stats.component';
import LibraryProgressionStats from '../components/library-progression-stats/library-progression-stats.component';

const config = {
  angle: 0,
  spread: 360,
  startVelocity: 40,
  elementCount: 40,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const PlayDefaultResult = ({ test, attempt, repeat }: {
  test: TTest,
  attempt: number,
  repeat: () => void
}) => {
  const [successPercent, setSuccessPercent] = useState(0.0)
  const [showConfetti, setShowConfetti] = useState(false);
  const [canRepeat, setCanRepeat] = useState(false);
  const [audio] = useState({
    end: new Audio(end),
    perfect: new Audio(perfect),
  })
  // console.log('is online?', navigator.onLine)

  useEffect(() => {
    audio.end.play();
    audio.perfect.load();

    const results = (test.stats.success / test.stats.total) * 100;
    const timer = setTimeout(() => {
      setSuccessPercent(results);
    }, 600)

    let timer2: NodeJS.Timeout;
    if (results === 100) {
      timer2 = setTimeout(() => {
        setShowConfetti(true);
        audio.perfect.play();
      }, 2500)
    }

    setCanRepeat((Test.canRepeatTest(test) && attempt === 1));

    return () => {
      clearTimeout(timer);
      if (timer2) clearTimeout(timer2);
    }
  }, [])

  const retry = () => {
    console.log('RETRY WRONG ANSWERS')
    repeat();
  }

  // Results page
  return (
    <div
      className="w-100 h-100 pt-4"
    >
      <Container>
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 8, offset: 2 }}
          >
            <motion.div
              animate={{
                scale: [0.8, 1],
                opacity: [0.5, 1],
              }}
              transition={{ duration: 0.2 }}
              className="card shadow mb-4"
            >
              <Card.Body>
                <div className="d-flex justify-content-center mb-1">
                  <Confetti active={showConfetti} config={config} />
                  <div className="result-graph">
                    <CircularProgressbarWithChildren
                      value={successPercent}
                      background={false}
                      styles={buildStyles({
                        rotation: 0.5 + (1 - 50 / 100) / 2,
                        pathTransitionDuration: 2,
                        pathColor: 'rgb(2 210 50)',
                        trailColor: '#f3f3f3',
                      })}
                      className="thin-trail"
                    >
                      <div>
                        <span className="percentage-number">
                          <CountUp end={successPercent} delay={0} useEasing />
                        </span>
                        <span className="ml-sm-1 percentage-icon">%</span>
                      </div>
                      <div>aciertos</div>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
                <div className="mb-4 text-center">
                  { test.stats.empty }
                  {' '}
                  sin contestar
                </div>
                { (canRepeat)
                  && (
                  <div className="text-center mb-2">
                    <Button onClick={() => retry()} variant="info">Reintentar fallidas</Button>
                  </div>
                )}
                <div className="text-center">
                  <Link to={`/test/${test.id}`}>
                    <Button variant="link">Ver corrección</Button>
                  </Link>
                </div>
              </Card.Body>
            </motion.div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 8, offset: 2 }}
          >
            <motion.div
              animate={{
                translateY: [80, 0],
                opacity: [0, 1],
              }}
              transition={{ duration: 0.2, delay: 0.3 }}
              className="card shadow-sm mb-3"
              style={{ zIndex: -1 }}
            >
              <TestHistoricStats {...test} />
            </motion.div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 8, offset: 2 }}
            className="mb-4"
          >
            <motion.div
              animate={{
                translateY: [80, 0],
                opacity: [0, 1],
              }}
              transition={{ duration: 0.2, delay: 0.35 }}
              className="card shadow-sm"
              style={{ zIndex: -1 }}
            >
              <LibraryProgressionStats
                libraries={test.config.libraries as TLibrary[]}
                content={test.content}
              />
            </motion.div>
          </Col>
        </Row>
        <Row>
          <Col className="mb-5 text-center">
            <Link to={`/test/${test.id}`}>
              <Button variant="link">Ver corrección del test</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default PlayDefaultResult;
