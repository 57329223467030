import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import UserDedicationStats from 'packages/FreeUserApp/containers/home/components/user-dedication-stats/user-dedication-stats.component';
import { RootState } from 'packages/_core/app.store';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryDispatch } from 'packages/_core/library/thunk.type';
import { thunkGetLibraries } from 'packages/_core/library/thunks/library.thunk';
import { thunkGetTests } from 'packages/_core/tests/thunks/tests.thunk';
import ActiveTestNotify from './components/active-test-notify/active-test-notify.component';
import UserFeedContainer from './feed.factory';

export default function HomePage() {
  const { active } = useSelector((state: RootState) => state.tests);
  const dispatch: LibraryDispatch = useDispatch();
  useEffect(() => {
    dispatch(thunkGetLibraries())
    dispatch(thunkGetTests())
  }, [])

  return (
    <>
      <Container className="main-container-padded">
        <Row>
          <Col md={{ span: 12, order: 2 }} lg={{ span: 7, order: 1 }}>
            {
              (active) && (
                <div className="d-block d-md-none">
                  <ActiveTestNotify testId={active} />
                </div>
              )
            }
            <div style={{ padding: '0 6px' }}>
              <h3>Como empezar</h3>
              <hr />
            </div>
            <UserFeedContainer />
          </Col>
          <Col md={{ span: 12, order: 1 }} lg={{ span: 5, order: 2 }}>
            {
              (active) && (
                <div className="d-none d-md-block">
                  <ActiveTestNotify testId={active} />
                </div>
              )
            }
            <Card className="mb-5">
              <Card.Body style={{ padding: 0 }}>
                <UserDedicationStats />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
