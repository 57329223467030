import React from 'react';

import TLibrary from 'packages/_core/types/TLibrary';

import './topic-tag.scoped.scss';
import { Badge } from 'react-bootstrap';

const TopicTag = (library: TLibrary) => (
  <div className="border rounded border-left-3 d-inline-block mr-2">
    <div className="d-flex p-1 tag-container">
      <div className="badge badge-info mr-1">{' '}</div>
      {library.name}
    </div>
  </div>
)
export default TopicTag;
