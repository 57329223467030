import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'packages/_core/app.store';
import TLibrary from 'packages/_core/types/TLibrary';
import { Library } from 'packages/_core/models/Library';
import { thunkRetrieveLibrary } from 'packages/_core/library/thunks/library.thunk';
import { LibraryDispatch } from 'packages/_core/library/thunk.type';
import { LQuestion } from 'packages/_core/types/TQuestion';
import { Card, Form, InputGroup } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from 'packages/FreeUserApp/shared/loaders/windows-loader.component';
import LibraryStats from './components/library-stats/library-stats.component';
import LibraryNotValid from '../error/library-not-valid-id';
import QuestionCard from './components/question-card/question-card.component';
import NoQuestionsCard from './components/no-questions-card/no-questions-card.component';
import NoQuestionsFoundCard from './components/no-questions-found-card/no-questions-found-card.component';
import QuestionCreation from './components/question-creation/question-creation.component';
import CreateQuestionActionCard from './components/create-question-action-card/create-question-action-card.component';

import './library-detail.scoped.scss';
import LoadingPage from '../../loading/loading.page';

export const LibraryDetail = () => {
  const dispatch: LibraryDispatch = useDispatch();
  const [library, setLibrary] = useState<TLibrary | null>(null);

  // Search / filtering relaged
  const [filteredQuestions, setFilteredQuestions] = useState<LQuestion[]>([])
  const [searchText, setSearchText] = useState('')

  // Question creation related
  const [creating, setCreating] = useState(false)

  const { libraries } = useSelector((state: RootState) => state);
  const { id } = useParams<{ id: string }>();
  const urlId = Number(id);

  const getLibraryData = () => {
    const tmpLib = Library.findById(libraries.libraries, urlId);
    if (tmpLib) setLibrary(tmpLib);
    else dispatch(thunkRetrieveLibrary(urlId))
  }

  const filterQuestions = (filter: string) => {
    setSearchText(filter);

    if (filter.length === 0) setFilteredQuestions(library?.questions || []);

    let { questions } = library as TLibrary;
    const filterText = filter.toLowerCase();

    if (library) {
      questions = library.questions.filter(quest => {
        let found = quest.text.toLowerCase().indexOf(
          filterText,
        ) !== -1
        if (found) return true;
        let i = 0;
        while (i < quest.answers.length && !found) {
          if (quest.answers[i].text.toLowerCase().indexOf(
            filterText,
            ) !== -1) {
              found = true;
            }
            i++;
        }
        return found;
      })
      setFilteredQuestions(questions);
    }
  }

  useEffect(() => {
    getLibraryData();
    if (library) setFilteredQuestions(library.questions);
  }, [])

  useEffect(() => {
    if (urlId < 0) return;
    getLibraryData();
    if (library) setFilteredQuestions(library.questions);
  }, [libraries.libraries, library]);

  const startCreation = (start: boolean = true) => {
    setCreating(start);
  }

  if (urlId >= 0) {
    if (!library) return <LoadingPage />
    if (library.id === urlId) {
      return (
        <>
          <Container className="main-container-padded">
            <Row>
              <Col md={12}>
                <Card className="mb-4 title-card">
                  <Card.Body className="d-flex flex-wrap p-2 p-sm-3 align-items-baseline">
                    <h3 style={{ width: '100%' }}>{ library.name }</h3>
                    <p>{ library.description }</p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <LibraryStats {...library as TLibrary} />
              </Col>
            </Row>
            <Form.Row className="mb-3 mt-1">
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon2" style={{ borderRadius: '30px 0 0 30px' }}>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      onChange={(e: any) => filterQuestions(e.target.value)}
                      type="text"
                      placeholder="Busca por título o respuesta"
                      disabled={library.questions.length === 0}
                      style={{ borderRadius: '0 30px 30px 0' }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Form.Row>
            <Row>
              <Col md={12}>
                {
                  (creating) ? (
                    <QuestionCreation library={library.id} createCallback={startCreation} />
                  ) : searchText === '' && library?.questions.length > 0 && (
                    <CreateQuestionActionCard createCallback={startCreation} />
                  )
                }
                {
                  filteredQuestions.map(quest => (!quest.deletion) && <QuestionCard key={`question-card-container-${quest.id}`} question={quest} libraryId={library.id} />)
                }
                {
                  (library?.questions.length === 0 && library?.questions.length === 0 && !creating && searchText === '') && (
                    <NoQuestionsCard createCallback={startCreation} />
                  )
                }
                {
                  (filteredQuestions.length === 0 && library?.questions.length > 0 && !creating && searchText !== '') && (
                    <NoQuestionsFoundCard createCallback={startCreation} search={searchText} />
                  )
                }
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
  return <LibraryNotValid />
}
export default LibraryDetail;
