import { LQuestion } from 'packages/_core/types/TQuestion';
import { AppThunk } from '../thunk.type';
import {
 addQuestion, updateLibrary,
} from '../actions';
import { ResponseQuestion } from '../types/response-types';
import { Library } from '../../models/Library';

export const thunkCreateQuestion = (question: LQuestion): AppThunk => async (
    dispatch,
    getState,
    api,
  ) => api.post<ResponseQuestion>('/question', question).then(
    ({ data }) => {
      const lib = (data.data.library) ? Library.findById(getState().libraries.libraries, data.data.library) : null;
      if (lib) { // Library exists
        const quest = Library.findQuestionIndexById(lib.questions, question.id) // Find the non-saved question
        const questionL: LQuestion = { ...data.data, library: lib.id }; // convert server TQuestion to LQuestion type
        if (quest > -1) {
          lib.questions[quest] = questionL
          dispatch(updateLibrary(lib)); // update question
        } else {
          dispatch(addQuestion(questionL)); // create question
        }
      }
      return Promise.resolve(data.data);
    },
    (err) => err,
  ).catch((e) => e);
