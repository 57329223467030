import React from 'react';
import {
 Navbar, Nav, Container, Col, Row,
} from 'react-bootstrap';
import { generatePath, NavLink, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TNavbarSubmenu } from './types';

import './navbar.scoped.scss';

const NavbarSubComponent = (props: TNavbarSubmenu) => {
  const params = useParams();
  return (
    <Navbar
      variant="light"
      className="mb-5 subheader-nav mobile"
    >
      <Container>
        <Col xs={12} className="d-flex justify-content-between align-items-center p-0">
          <Nav className="mr-auto main">
            {
              props.links?.map(link => (
                <NavLink
                  key={`subnav-link-${link.text}`}
                  activeClassName="nav-link-active"
                  className={`nav-link text-center ${link.className}`}
                  to={generatePath(link.route, params)}
                >
                  {link.text}
                </NavLink>
              ))
          }
          </Nav>
          <div className="secondary">
            {
              props.buttons?.map(btn => (
                <NavLink
                  key={`subnav-button-${btn.text}`}
                  title={btn.text}
                  activeClassName="nav-link-active"
                  className={`nav-link text-center ${btn.className}`}
                  exact
                  to={generatePath(btn.route, params)}
                >
                  <FontAwesomeIcon icon={btn.icon} />
                </NavLink>
              ))
          }
          </div>
        </Col>
      </Container>
    </Navbar>
)
}

export default NavbarSubComponent;
