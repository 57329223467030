import React from 'react';

import { Alert } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import enterIcon from '../../../../../assets/icons/svg/enter-key.svg';

const CreationHint = () => (
  <div className="mt-4">
    <div className="text-small font-italic mb-2 text-muted">
      <b>Truco:</b>
      {' '}
      Pulsa
      {' '}
      <img width="22px" src={enterIcon} alt="Intro" />
      {' '}
      (intro) para añadir una nueva respuesta.
    </div>
    <div className="d-none pl-5 text-small font-italic">
      Pulsa
      {' '}
      <FontAwesomeIcon icon={faPlus} />
      {' '}
      para marcar/desmarcar la respuesta como válida
    </div>
  </div>
  )

export default CreationHint;
