import React, { useState } from 'react';

// Bootstrap
import Col from 'react-bootstrap/Col';

import './question-card.scoped.scss';
import TQuestion, { LQuestion } from 'packages/_core/types/TQuestion';
import { Button, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useDispatch } from 'react-redux';
import { thunkDeleteQuestion } from 'packages/_core/library/thunks/question-delete.thunk';
import { thunkUpdateQuestion } from 'packages/_core/library/thunks/question-update.thunk';
import { replaceQuestion, updateQuestion } from 'packages/_core/library/actions';
import { Question } from 'packages/_core/models/Question';
import { isMobileDevice } from 'packages/FreeUserApp/services/device.service';
import QuestionForm from '../question-form/question-form.component';
import QuestionCardStats from './stats.component';

export interface relativeQuestion extends TQuestion {
  relativeId: number
}

const QuestionCard = ({ question, libraryId }: { question: LQuestion, libraryId: number }) => {
  const [editing, setEditing] = useState(false)
  const [modifiedData, setModifiedData] = useState(question)
  const [canSave, setCanSave] = useState(false)
  const [modified, setModified] = useState(false)

  const dispatch = useDispatch();
  const activeAlert = withReactContent(Swal);

   /**
   * EVENT HANDLERS
   */
  const deleteQuestion = () => {
    activeAlert.fire({
      icon: 'warning',
      title: '¿Borrar la pregunta?',
      text: 'Podrás recuperarla desde la papelera si te arrepientes.',
      confirmButtonText: 'Borrar',
      showCancelButton: true,
      cancelButtonText: 'No, conservar',
      confirmButtonColor: '#cccccc',
      cancelButtonColor: '#62b53c',
      allowOutsideClick: true,
      focusCancel: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(thunkDeleteQuestion({ ...question, library: libraryId }))
      }
    })
  }

  const onQuestionUpdate = (newQuestion: LQuestion) => {
    setModifiedData(Question.removeEmptyAnswers(newQuestion));
    setModified(true);
  }

  const startEditing = () => {
    setEditing(true);
  }

  const saveChanges = () => {
    dispatch(replaceQuestion(modifiedData));
    dispatch(thunkUpdateQuestion(modifiedData));
    setEditing(false);
    activeAlert.fire({
      text: '¡Pregunta actualizada!',
      toast: true,
      position: (isMobileDevice()) ? 'bottom' : 'top',
      timer: 5000,
      showConfirmButton: false,
    })
  }

  const updateSave = (canDo: boolean) => {
    if (canDo && modified) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }

  return (
    <div key={`question-card-${question.id}`} className="d-flex QuestionCardRow" style={{ flexWrap: 'wrap' }}>
      <Col
        sm={2}
        className="QuestionInfoCol d-flex flex-wrap align-content-between justify-content-center"
      >
        <QuestionCardStats stats={question.stats} />
      </Col>
      <Col className="QuestionContent">
        <QuestionForm
          question={question}
          onSaveChange={updateSave}
          onUpdate={onQuestionUpdate}
          forceSave={saveChanges}
          editing={editing}
        >
          {
            (editing === false)
            ? (<Button variant="link" onClick={startEditing} className="edit-btn">Editar pregunta</Button>)
            : (
              <>
                <Button variant="primary" disabled={!canSave} onClick={saveChanges}>Guardar</Button>
                <Button variant="outline-primary" onClick={() => setEditing(false)} className="ml-4">Cancelar</Button>
              </>
            )
          }
        </QuestionForm>
        <div className="delete-button">
          <Button onClick={deleteQuestion} variant="link">
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </div>
      </Col>
    </div>
  );
}

export default QuestionCard;
