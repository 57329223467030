import React from 'react';

import './signup.scoped.scss';
import { Container, Form, Row } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';
import { Formik } from 'formik';
import { AxiosError } from 'axios';
import { UserDispatch } from 'packages/_core/user/thunk.type';
import { useDispatch, useSelector } from 'react-redux';
import { thunkSignup } from 'packages/_core/user/thunks/signup.thunk';
import { RootState } from 'packages/_core/app.store';

const controlStyle = {
  overflow: 'hidden',
  marginBottom: 0,
  border: '1px solid #dcdcdc',
  padding: '15px',
  backgroundColor: '#f8f8f8',
  height: 'auto',
};

export const SignupPage = () => {
  const dispatch: UserDispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)

  const submit = (values: any, events: any) => {
    console.log(values)
    dispatch(thunkSignup(values)).catch((e: AxiosError) => {
      console.log('error data', { error: e, resp: e.response })
      if (e.response?.status === 422) {
        let errs = {};
        e.response.data.error.details.forEach(({ field, message }: {
          field: string,
          message: string,
        }) => {
          errs = { ...errs, ...{ [field]: message } };
        });
        events.setStatus(errs)
      }
    })
  }

  const schema = yup.object().shape({
    password: yup.string()
      .min(8, 'Demasiado corta')
      .max(30, 'Demasiado larga')
      .required('No puede estar en blanco'),
    email: yup.string()
      .email('Email no válido')
      .required('El email es obligatorio'),
    username: yup.string().matches(
        /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/igm,
        'Usuario no válido',
      ).required('Debes tener un nombre público'),
  });

  if (user) {
    return <Redirect to="/" />
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={submit}
      initialValues={{
        email: '',
        username: '',
        password: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        status,
      }) => (
        <Container className="pt-5">
          <Row className="justify-content-center">
            <div className="box">
              <div className="mb-4" id="heading">
                <h3>Opositario</h3>
                Regístrate para mejorar tu estudio rápido y divertido.
              </div>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="validationFormik01">
                  <Form.Control
                    style={controlStyle}
                    placeholder="Correo electrónico"
                    type="email"
                    className="form-control"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && (!!errors.email || !!status?.email)}
                    isValid={touched.email && !errors.email && !status?.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {status?.email || errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationFormik02">
                  <Form.Control
                    style={controlStyle}
                    placeholder="Usuario"
                    type="text"
                    className="form-control"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.username && (!!errors.username || !!status?.username)}
                    isValid={touched.username && !errors.username && !status?.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {status?.username || errors.username}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="validationFormik03">
                  <Form.Control
                    style={controlStyle}
                    placeholder="Contraseña"
                    type="password"
                    className="form-control"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && (!!errors.password || !!status?.password)}
                    isValid={touched.password && !errors.password && !status?.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {status?.password || errors.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <input disabled={!isValid} type="submit" value="Crear cuenta" className="btn btn-block btn-primary" />

                <span className="d-block text-center mt-3 text-muted">&mdash;&mdash; o &mdash;&mdash;</span>

                <div className="mt-4 text-center small text-muted">
                  Al registrarte, aceptas nuestras
                  {' '}
                  <Link to="/">Condiciones</Link>
                  . Obtén más información sobre cómo recopilamos, usamos y compartimos tu información en la
                  {' '}
                  <Link to="/">Política de datos</Link>
                  , así como el uso que hacemos de las cookies y tecnologías similares en nuestra
                  {' '}
                  <Link to="/">Política de cookies</Link>
                  .
                </div>

                {
              // <span className="d-block text-center my-4 text-muted">&mdash; or &mdash;</span>
            }

                <div className="social-login" style={{ display: 'none' }}>
                  <a href="/" className="facebook btn d-flex justify-content-center align-items-center">
                    <span className="icon-facebook mr-3" />
                    {' '}
                    Login with Facebook
                  </a>
                  <a href="/" className="twitter btn d-flex justify-content-center align-items-center">
                    <span className="icon-twitter mr-3" />
                    {' '}
                    Login with  Twitter
                  </a>
                  <a href="/" className="google btn d-flex justify-content-center align-items-center">
                    <span className="icon-google mr-3" />
                    {' '}
                    Login with  Google
                  </a>
                </div>
              </Form>
            </div>
          </Row>
          <Row className="justify-content-center mt-3">
            <div className="box text-center">
              ¿Tienes una cuenta?
              {' '}
              <Link to="/login">Entrar</Link>
            </div>
          </Row>
        </Container>
      )}
    </Formik>
  )
}

export default SignupPage;
