import React, { useEffect, useState } from 'react';

import { Prompt, Redirect, useParams } from 'react-router-dom';

import { RootState } from 'packages/_core/app.store';
import { useDispatch, useSelector } from 'react-redux';

import TTest, { TType } from 'packages/_core/types/TTest';
import { TestDispatch } from 'packages/_core/tests/thunk.type';
import { thunkRetrieveTest } from 'packages/_core/tests/thunks/tests.thunk';
import { findTestInList } from 'packages/_core/tests/helper.fn';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { isMobileDevice } from 'packages/FreeUserApp/services/device.service';
import { updateTest } from 'packages/_core/tests/actions';
import PlayDefault from './default/default-play.page';
import PlayPractice from './practice/practice-play.page';
import LoadingPage from '../../loading/loading.page';
import TestNotValid from '../error/test-not-valid-id';

export const PlayHoc = () => {
    const dispatch: TestDispatch = useDispatch();
    const [test, setTest] = useState<TTest | null>(null);
    const [error, setError] = useState(false);
    const [leaveAttempts, setLeaveAttempts] = useState(0);

    const Alert = withReactContent(Swal);

    const { tests } = useSelector((state: RootState) => state);
    const { id } = useParams<{ id: string }>();
    const urlId = Number(id);

    const getTestData = () => {
      const tmpTest = findTestInList(tests.list, urlId);
      if (tmpTest) {
        setTest(tmpTest);
      } else {
        dispatch(thunkRetrieveTest(urlId))
        .catch((e) => setError(true))
      }
    }

    useEffect(() => {
        getTestData();
        showCC();
        return () => {
          if (test) {
            dispatch(updateTest({ ...test, allowRetry: false }))
          }
        }
    }, [])

    useEffect(() => {
        getTestData();
    }, [tests.list, tests.active])

    const handleBackButton = (action: any) => {
        // TODO: move this guard to the specific test play, as it may behave differently on the different types of test (games..)
        console.log('leave attempts', leaveAttempts, action)
        if (action === 'PUSH') return true;
        if (leaveAttempts >= 1) return true;

        Alert.fire({
            html: ' Pulsa de nuevo para abandonar el test',
            icon: 'info',
            toast: true,
            position: (isMobileDevice()) ? 'bottom' : 'top',
            timer: 5000,
            showConfirmButton: false,
        });

        setLeaveAttempts(leaveAttempts + 1)
        return false;
    }

    if (!test && !error) return <LoadingPage />; // No test in memory
    if (!test && error) return <TestNotValid />;
    if (test?.end && !test?.allowRetry) return <Redirect to={`/test/${test.id}`} />; // Test is finished, you cant do it again

    if (test?.config.type === TType.test) {
      // TODO: Depending on the test type, load one component or another
      return (
        <>
          <Prompt message={(location, action) => handleBackButton(action)} />
          <PlayDefault {...test as TTest} />
        </>
      )
    }
    return <PlayPractice {...test as TTest} />;
}
export default PlayHoc;

 /**
 * Show Creative Commons attributions on console
 */
const showCC = () => {
  console.log('Sound CC Attribution', {
    Unfa: 'https://freesound.org/people/unfa/sounds/181354/',
    Littlerobotsoundfactory:
      'https://freesound.org/people/LittleRobotSoundFactory/sounds/270467/',
    Bertrof: 'https://freesound.org/people/Bertrof/sounds/131657/',
    Rhodesmas: 'https://freesound.org/people/rhodesmas/sounds/320655/',
    Vitovsky1: 'https://freesound.org/people/vitovsky1/sounds/497018/',
    Kastenfroch: 'https://freesound.org/people/Kastenfrosch/sounds/521949/',
    NenadSimic: 'https://freesound.org/people/NenadSimic/sounds/171697/',
    InspectorJ: 'https://freesound.org/people/InspectorJ/sounds/397441/',
    Reclure: 'https://freesound.org/people/Raclure/sounds/405548/',
    fellur: 'https://freesound.org/people/fellur/sounds/429724/',
    chripei: 'https://freesound.org/people/chripei/sounds/165491/',
    ryusa: 'https://freesound.org/people/ryusa/sounds/531132/',
  });
}
