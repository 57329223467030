import { setError } from 'packages/_core/error/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AxiosError, AxiosInstance } from 'axios';
import store from '../../_core/app.store'
import { getHumanMessage } from '../services/error.service';
import { userStorage } from '../services/user.service';

const alert = withReactContent(Swal);

// Add a response interceptor
export const addErrorHandler = (api: AxiosInstance) => (
  api.interceptors.response.use(
    (response) => ((response.status === 200) ? response : Promise.reject(response)),
    (error: AxiosError) => {
      console.log('error cartche by interceptor', { error, resp: error.response })
      // error.data = error.response as TResponseError;
      if (error.response) {
        if (error.response.status === 401) { // Redirect to login page
          // Clear local stored user data
          userStorage.remove()
          alert.fire({
              icon: 'warning',
              title: 'Se ha cerrado la sesión',
              text: 'Inicia sesión de nuevo para continuar',
              allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.replace('/');
            }
          })
        } else {
        // Other errors
          store.dispatch(setError({
            code: error.response.status,
            text: error.response.statusText,
            date: new Date(),
          }));
          /* alert.fire({
            icon: 'warning',
            title: 'OPS!',
            text: getHumanMessage(
              error.response.status,
              error.response.data?.error?.type || null,
            ),
            toast: true,
            position: 'bottom',
            timer: 7500,
          }); */
        }
      // TODO: Call different actions depending on error, for example clearing logged in data and redirecting to login
      } else if (error.request) {
        // client never received a response, or request never left
        // TODO: Show different types of errors depending on where the user is (for example a red bar on bottom/top, or a full page like happens now)
        store.dispatch(setError({
          code: 0,
          text: 'ERR_CONNECTION_TIMED_OUT',
          date: new Date(),
          fatal: true,
          route: window.location.pathname,
        }));
      } else {
        // anything else
        console.error('UNHANDLED API INTERCEPTOR ERROR')
      }
      console.log('interceptor catched error', error)
      throw error
      // return Promise.resolve();
    },
  )
)
