import React, { useState } from 'react';
import TTest from 'packages/_core/types/TTest';

import NavBar from 'packages/FreeUserApp/containers/app/components/navbar/navbar.component';
import {
  Button,
  Card,
  Col, Container, Row,
} from 'react-bootstrap';
import TLibrary from 'packages/_core/types/TLibrary';

import Moment from 'react-moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import './unfinished.scss';
import './unfinished.scoped.scss';
import { motion } from 'framer-motion';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { thunkUpdateTest } from 'packages/_core/tests/thunks/tests.thunk';
import { TestDispatch } from 'packages/_core/tests/thunk.type';
import { setActiveTest } from 'packages/_core/tests/actions';

const TestUnfinishedResume: React.FC<TTest> = (test) => {
  const libraries = test.config.libraries as TLibrary[];
  const [rotate, setRotate] = useState(0);
  const [redirect, setRedirect] = useState(false);

  const dispatch: TestDispatch = useDispatch();

  const playTest = () => {
    setRotate((prevState) => prevState + 360)
    dispatch(setActiveTest(test.id))
    setRedirect(true);
  }

  const cancelTest = () => {
    dispatch(thunkUpdateTest({
      ...test,
      end: new Date().toISOString(),
    }))
  }

  if (redirect) {
    return <Redirect to={`/test/${test.id}/play`} />
  }

  return (
    <>
      <Container className="main-container-padded">
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 8, offset: 2 }}
          >
            <Card>
              <Card.Body>
                <h3>Continuar test</h3>
                {(test.start) && (
                <p className="date">
                  <Moment
                    date={test.start}
                    fromNow
                  />
                </p>
                )}
                <p className="button-play-container">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="button-play"
                    onClick={() => playTest()}
                    animate={{
                        rotate,
                        transition: { duration: 1, ease: 'circOut' },
                    }}
                  >
                    <FontAwesomeIcon icon={faPlay} size="3x" />
                  </motion.button>
                </p>
                <p className="medium text-muted explanation">
                  No has terminado, si no
                  <br />
                  quieres seguir puedes
                  <br />
                  <Button variant="link" onClick={() => cancelTest()} className="cancel test">cancelar el test</Button>
                </p>
              </Card.Body>
            </Card>
            <h3 id="libraries-title">Librerías</h3>
            <Card className="library-list">
              <Card.Body>
                {libraries.map((lib) => <div key={lib.id}>{lib.name}</div>)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default TestUnfinishedResume;
