import * as User from './types';

const initialState: User.UserStore = null;

export default function userReducer(
  state = initialState,
  action: User.ActionTypes,
): User.UserStore {
  console.log(`User reducer => [${action.type}]`, action.payload);
  switch (action.type) {
    case User.SET_USER:
      if (action.payload !== null) {
        return { ...action.payload };
      }
      return null;
    default:
      return state;
  }
}
