import { userStorage } from 'packages/FreeUserApp/services/user.service';
import TUser from 'packages/_core/types/TUser';
import { setUser } from '../actions';
import { AppThunk } from '../thunk.type';
import { ResponseUser } from '../types/response-types';

export const thunkUserLogin = ({ user, pass }: { user: string, pass: string }): AppThunk<Promise<TUser | ResponseUser>> => async (
    dispatch,
    getState,
    api,
  ) => {
    // document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    api.defaults.withCredentials = true;
    return api.post<ResponseUser>(
        '/user/login',
        `username=${user}&password=${pass}`,
        { withCredentials: true },
        ).then(
            ({ data }) => {
                if (data.data) {
                    userStorage.saveData(data.data);
                    dispatch(setUser(data.data))
                    return data.data;
                }
            return data;
            },
            (err) => Promise.reject(err),
      ).catch((e) => Promise.reject(e))
  }
