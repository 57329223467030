import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { motion } from 'framer-motion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import TTest from 'packages/_core/types/TTest';
import TopicTag from 'packages/FreeUserApp/shared/topic-tag/topic-tag.component';
import TLibrary from 'packages/_core/types/TLibrary';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { setActiveTest } from 'packages/_core/tests/actions';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { TestDispatch } from 'packages/_core/tests/thunk.type';
import { useDispatch } from 'react-redux';

import './test-card.scss';

const variants = {
  show: {
    y: 0,
    opacity: 1,
    duration: 0.1,
    transition: {
      y: { stiffness: 50, duration: 0.05 },
    },
  },
  hide: {
    y: 50,
    opacity: 0,
  },
};

const TestCard = (test: TTest) => {
    const history = useHistory();
    const [libraries, setLibraries] = useState<TLibrary[]>([])
    const [rotate, setRotate] = useState(0);
    const [redirectTimer, setRedirectTimer] = useState<NodeJS.Timeout | undefined>(undefined);

    const dispatch: TestDispatch = useDispatch();

    const percent = (test.stats.success / test.stats.total) * 100;

    useEffect(() => {
        setLibraries(test.config.libraries as TLibrary[]);
        return () => {
          if (redirectTimer) clearTimeout(redirectTimer)
        }
    }, [])

    const playTest = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setRotate((prevState) => prevState + 360)
      dispatch(setActiveTest(test.id))
      setRedirectTimer(
        setTimeout(() => {
          history.push(`/test/${test.id}/play`)
        }, 350),
      )
    }

    return (
      <motion.div
        variants={variants}
        initial="hidden"
        animate="show"
        className="card UserFeedElement"
        onClick={() => history.push(`/test/${test.id}`)}
        key={`testcard_${test.id}`}
      >
        <Card.Body>
          <Row>
            <Col xs={3} sm={2} className="pr-0 d-flex justify-content-center align-items-center">
              {
                (!test.end) ? (
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="button-play-card"
                    onClick={(e) => playTest(e)}
                    animate={{
                        rotate,
                        transition: { duration: 1, ease: 'circOut' },
                    }}
                  >
                    <FontAwesomeIcon icon={faPlay} size="3x" />
                  </motion.button>
                )
                : (
                  <CircularProgressbarWithChildren
                    value={percent || 0}
                    strokeWidth={8}
                    styles={buildStyles({
                      pathColor: '#467F8E',
                      trailColor: '#f3f3f3',
                    })}
                  >
                    <div style={{
                      fontSize: 12,
                      marginTop: -5,
                      textAlign: 'center',
                      lineHeight: '0.8em',
                    }}
                    >
                      <span style={{ fontSize: '1.4em' }}>
                        {
                          percent || 0
                        }
                      </span>
                      <span style={{ fontSize: '0.8em' }}>
                        %
                      </span>
                      <br />
                      aciertos
                    </div>
                  </CircularProgressbarWithChildren>
                )
              }
            </Col>
            <Col xs={9} sm={10}>
              {
                  libraries.map((lib) => <TopicTag key={`testtag_${test.id}.${lib.id}`} {...lib} />)
              }
              <div className="d-none d-sm-flex justify-content-around border-top pt-3 mt-2">
                <div>
                  <small className="text-muted">
                    <Moment fromNow>{test.creation}</Moment>
                  </small>
                </div>
                <div>
                  <small className="text-muted">
                    {(
                        `${(test.stats.success + test.stats.fail)}/${test.stats.total}`
                    )}
                    {' '}
                    respondidas
                  </small>
                </div>
                <div>
                  <small className="text-muted">
                    {
                        (test.end && test.start)
                        ? (
                          <Moment
                            duration={test.start}
                            date={test.end}
                          />
                        ) : <span>No terminado</span>
                    }
                  </small>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="d-block d-sm-none">
            <Col xs={12}>
              <div className="border-top pt-1 mt-2">
                <div>
                  <small className="text-muted">
                    <b>Creado:</b>
                    {' '}
                    <Moment fromNow>{test.creation}</Moment>
                  </small>
                </div>
                <div>
                  <small className="text-muted">
                    <b>Respondidas:</b>
                    {' '}
                    {(
                        `${(test.stats.success + test.stats.fail)}/${test.stats.total}`
                    )}
                    {' '}
                    preguntas
                  </small>
                </div>
                <div>
                  <small className="text-muted">
                    <b>Duración:</b>
                    {' '}
                    {
                        (test.end && test.start)
                        ? (
                          <Moment
                            duration={test.start}
                            date={test.end}
                          />
                        ) : <span>No terminado</span>
                    }
                  </small>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </motion.div>
    )
}
export default TestCard;
