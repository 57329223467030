import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { RootState } from 'packages/_core/app.store';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryDispatch } from 'packages/_core/library/thunk.type';
import { thunkGetLibraries } from 'packages/_core/library/thunks/library.thunk';
import { thunkGetTests } from 'packages/_core/tests/thunks/tests.thunk';
import TestFactory from './components/test-factory/test.factory';
import LibraryFilter from './components/library-filter/library-filter.component';
import { FilterLibraries } from './components/library-filter/filter.types';
import NoLibrariesFound from './components/no-libraries-found/no-libraries-found.component';
import NoTestsFound from './components/no-tests-found/no-tests-found.component';
import LoadingPage from '../../loading/loading.page';

export const TestListPage = () => {
    const dispatch: LibraryDispatch = useDispatch();
    const [selectedLibraries, setSelectedLibraries] = useState<number[]>([])

    const { tests, libraries } = useSelector((state: RootState) => state);

    useEffect(() => {
      dispatch(thunkGetLibraries())
      dispatch(thunkGetTests())
    }, []);

    /**
     * Receive the libraries filter with their state to send it to other components
     * @param currentState Libraries with an extra parameter "selected"
     */
    const updateFilter = (currentState: FilterLibraries[]) => {
        const tmp: number[] = [];
        currentState.forEach((lib) => (lib.selected) && tmp.push(lib.id))
        setSelectedLibraries(tmp);
    }

    // User never used the app. no libraries nor tests done
    if (libraries.libraries.length === 0 && libraries.loading === false
      && tests.list.length === 0 && tests.loading === false) {
      return (
        <Container className="main-container-padded">
          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <NoLibrariesFound />
            </Col>
          </Row>
        </Container>
      )
    }

    // User has some library, but no tests
    if (tests.list.length === 0 && tests.loading === false) {
      return (
        <Container className="main-container-padded">
          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <NoTestsFound libraries={libraries.libraries} />
            </Col>
          </Row>
        </Container>
      )
    }

    // No tests nor libraries, and loading. Wait for response before showing anything
    if ((tests.list.length === 0 && tests.loading)
      || (libraries.libraries.length === 0 && libraries.loading)) {
        return <LoadingPage />;
    }

return (
  <>
    <Container className="main-container-padded">
      <Row>
        <Col md={12} lg={7}>
          <TestFactory filter={{ libraries: selectedLibraries }} />
        </Col>
        <Col md={{ span: 12, order: 'first' }} lg={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }} sm={{ order: 'first' }}>
          <Card body className="mb-3 d-none d-sm-block">
            <Link
              to="/test/nuevo"
              className="btn btn-primary btn-lg btn-block"
            >
              Hacer test ahora
            </Link>
          </Card>
          <Card body className="mb-3">
            <h3>Filtrar por Librerías</h3>
            <LibraryFilter
              libraries={libraries.libraries}
              selectionCallback={updateFilter}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  </>
)
}
export default TestListPage;
