import TTest, { TTConfig } from '../types/TTest';
import * as Tests from './types/store-types';

/**
 * Sets the list of tests from the provided one.
 * @param tests Array of tests to set
 */
export function setTests(tests: Array<TTest>): Tests.ActionTypes {
  return {
    type: Tests.SET_TESTS,
    payload: tests,
  };
}

/**
 * Adds a test to the list
 * @param test Test to add
 */
export function addTest(test: TTest): Tests.ActionTypes {
  return {
    type: Tests.ADD_TEST,
    payload: test,
  };
}

/**
 * Updates the provided test with the provided data from the history
 * @param test new test data
 */
export function updateTest(test: TTest): Tests.ActionTypes {
  return {
    type: Tests.UPDATE_TEST,
    payload: test,
  };
}

/**
 * Sets the active test or clears its value
 * @param id test id or null
 */
export function setActiveTest(id: number | null): Tests.ActionTypes {
  return {
    type: Tests.SET_ACTIVE_TEST,
    payload: id,
  };
}

export function setLoadingTests(newLoading: boolean): Tests.ActionTypes {
  return {
    type: Tests.SET_LOADING_TESTS,
    payload: newLoading,
  };
}
