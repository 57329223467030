import React from 'react';

// Bootstrap
import { motion } from 'framer-motion';
import Form from 'react-bootstrap/Form';

import Card from 'react-bootstrap/Card';

import 'packages/FreeUserApp/shared/checkbox/checkbox_effect.scss';
import { TTContent } from 'packages/_core/types/TTest';

import './question-card.scss';
import './question-card.scoped.scss';
import {
  faTimes, faCheck, faBan,
 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';
import FeedbackIcons from '../feedback-icons/feedback-icons.component';

library.add(faCheck)

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 300 : -300,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? 300 : -300,
    opacity: 0,
  }),
};

type TQprops = TTContent & {
  answerCallback(id: number): void;
  direction: number;
  answerId: number | undefined | null;
};

// Input Docs: https://react-circular-input.now.sh/examples#render-prop
const QuestionCard = ({
  id,
  direction,
  answer,
  question,
  ...props
}: TQprops) => (
  <>
    <motion.div
      key={`content-card-${id}`}
      initial="enter"
      animate="center"
      custom={direction}
      exit="exit"
      variants={variants}
      transition={{
          x: { type: 'spring', stiffness: 300, damping: 200 },
          opacity: { duration: 0.2 },
        }}
      className="card testProgress-card shadow"
    >
      { (answer) // Only draw feedback box on answered, if not it will block interaction
        && (
        <div className="question-card-feedback">
          <FeedbackIcons correct={answer?.correct} />
        </div>
      )}
      <Card.Body>
        <Card.Title>{question.text}</Card.Title>
        <div className="input-container">
          <Form.Group controlId="answers">
            {question.answers
                && question.answers.map((ans) => {
                  let ansClass = '';
                  let icon = faCheck;
                  if (answer && !answer.correct) {
                    if (ans.correct) {
                      ansClass = 'correct-answer';
                      icon = faCheck;
                    } else {
                      ansClass = 'wrong-answer';
                      icon = faBan;
                    }
                  }
                  return (
                    <Form.Check
                      key={ans.id}
                      tabIndex={0}
                      type="radio"
                      label={ans.text}
                      id={`answer-${ans.id}`}
                      name="answergroup"
                      className={`answer d-flex align-items-baseline ${ansClass}`}
                      value={ans.id}
                      checked={ans.id === props.answerId}
                      onChange={(e: any) => props.answerCallback(Number(e.target.value))}
                      disabled={!!answer}
                    />
                  )
                })}
          </Form.Group>
        </div>
      </Card.Body>
    </motion.div>
  </>
  );

export default QuestionCard;
