import React from 'react'

import './card-body-one-col.scoped.scss'

const CardBodyOneColComponent = ({ title, desc, ...props }:
    React.PropsWithChildren<{
      title: string,
      desc: string,
    }>) => (
      <>
        <h4 className="pt-2 mb-1">{title}</h4>
        <div className="d-flex d-sm-block flex-wrap">
          <div>
            {desc}
          </div>
          {props.children}
        </div>
      </>
    )

export default CardBodyOneColComponent;
