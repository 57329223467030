import React, { useContext } from 'react';
import TLibrary from 'packages/_core/types/TLibrary';
import {
 AccordionContext, ProgressBar, useAccordionToggle,
} from 'react-bootstrap';

import './library-toggle.scoped.scss';

interface iprops {
  eventKey: string,
  library: TLibrary
}
function LibraryToggle({ children, eventKey, library }: React.PropsWithChildren<iprops>) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => console.log('click'),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  let success = 0;
  const total = (library.content) ? library.content.length : 0;

  library.content?.forEach((cnt) => (cnt.answer?.correct) && success++)

  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      className="library-card-toggle"
      tabIndex={0}
    >
      <div className="title">
        {library.name}
        {children}
        <span>
          {success}
          /
          {total}
        </span>
      </div>
      <ProgressBar style={{ height: isCurrentEventKey ? '0.3rem' : '0.8rem', translate: '0px 6px' }}>
        <ProgressBar variant="success" now={(success / total) * 100} key={1} />
      </ProgressBar>
    </button>
  );
}
export default LibraryToggle;
