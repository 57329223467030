import React from 'react';
import { Link } from 'react-router-dom';

import bg from '../../../../../assets/illustrations/blimp-no-bg.svg'

import './no-libraries-found.scoped.scss';

const NoLibrariesFound = () => (
  <div className="container">
    <img className="bg-image" alt="Sin contenido" src={bg} />
    <div className="text-center mt-5">
      Nada por aquí...
      <br />
      <Link className="btn btn-primary mt-3" type="button" to="/librerias/nueva">¡Crear mi primera librería!</Link>
    </div>
  </div>
)

export default NoLibrariesFound;
