import TTest from '../types/TTest';
import { TestHistoryStore } from './types/store-types';

/**
 * Returns a test data by Its ID
 * @param list list of tests
 * @param id id of the test to find
 */
export const findTestInList = (list: TTest[], id: number) => list.find((test) => test.id === id)

/**
 * Returns the active test data or null if no active test
 * @param state curent test history store state
 */
export const getActiveTest = (state: TestHistoryStore) => (
    (state.active) ? state.list.find((test) => test.id === state.active) : null
)
