import TUser from '../types/TUser';

export const SET_USER = 'SET_USER';

export type UserStore = TUser | null

interface SetUser {
  type: typeof SET_USER;
  payload: TUser | null;
}

export type ActionTypes = SetUser;
