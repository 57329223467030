import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import './library-creation.scoped.scss';
import { Button, Form, Spinner } from 'react-bootstrap';

import { RootState } from 'packages/_core/app.store';
import { LibraryDispatch } from 'packages/_core/library/thunk.type';
import { thunkCreateLibrary, thunkGetLibraries } from 'packages/_core/library/thunks/library.thunk';
import { useSelector, useDispatch } from 'react-redux';
import LibraryListComponent from './components/library-list/library-list.component';

export default function LibraryCreationPage() {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [creating, setCreating] = useState(false);

  const history = useHistory();

  const { libraries } = useSelector((state: RootState) => state.libraries);
  const dispatch: LibraryDispatch = useDispatch();

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      setValidated(true);
      setCreating(true);
      dispatch(thunkCreateLibrary({
        name,
        description,
      })).then(res => {
        history.push(`/librerias/${res.id}`, { newlyCreated: true });
      })
    }
  };

  // Initially load user libraries
  useEffect(() => {
    dispatch(thunkGetLibraries())
}, []);

  return (
    <Container
      className="library-creation-container pt-2 pb-5"
    >
      <Row>
        <Col xs={12}>
          <div className="box">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="libraryName">
                <Form.Label>Nombre descriptivo</Form.Label>
                <Form.Control
                  autoFocus
                  required
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  disabled={creating}
                  placeholder="Ejemplo: Tema 1, legislación española..."
                  autoComplete="off"
                />
                <LibraryListComponent searchText={name} libraries={libraries} />
              </Form.Group>

              <Form.Group className="mt-5" controlId="libraryDescription">
                <Form.Label>
                  Descripción
                  <small className="ml-1">(Opcional)</small>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  disabled={creating}
                  placeholder="Descripción de contenidos..."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button disabled={creating} variant="primary" className="d-flex mt-4 btn-lg" type="submit">
                  {(creating) && (
                  <Spinner className="mr-2" animation="grow" variant="white" as="span" role="status">
                    <span className="sr-only">Creando...</span>
                  </Spinner>
                  )}
                  Crear librería
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
