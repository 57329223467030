import { Library } from 'packages/_core/models/Library';
import { LQuestion } from 'packages/_core/types/TQuestion';
import { LibraryStore } from '../types';

/**
 * Removes a question from the library array
 * @param state Current store state
 * @param newLib New question data to update the store or null to delete
 */
export const deleteQuestionReducer = (state: LibraryStore, question: LQuestion): LibraryStore => {
    console.log('STARTING RIGHT REDUCER --------')
    const libIndex = Library.findIndexById(state.libraries, question.library);
    const stateLibs = [...state.libraries];
    if (libIndex > -1) { // Library exists
      const questIndex = Library.findQuestionIndexById(stateLibs[libIndex].questions, question.id)
      if (questIndex > -1) { // Question exists
        console.log('found quest')
        stateLibs[libIndex].questions.splice(questIndex, 1)
        return { ...state, libraries: [...stateLibs] }
      }
        console.log('quest NOT FOUND')
    }
    console.log('lIB NOT FOUND')

    return state;
}
