import React, { useEffect, useState } from 'react';
import TTest from 'packages/_core/types/TTest';

import NavBar from 'packages/FreeUserApp/containers/app/components/navbar/navbar.component';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import TLibrary from 'packages/_core/types/TLibrary';
import Libraries from './components/library-resume/library-factory.component';
import TestData from './components/test-data/test-data.component';

const TestFinishedPage = (test: TTest) => {
  const [libraries, setLibraries] = useState<TLibrary[]>([])

  useEffect(() => {
    // Add content to each corresponding library
    const libs = test.config.libraries as TLibrary[];
    test.content.forEach((cnt) => {
      const idx = libs.findIndex((lib) => lib.id === cnt.question.library);
      if (idx !== -1) {
        if (!libs[idx].content) {
          libs[idx].content = [];
        }
        libs[idx].content?.push(cnt);
      }
    });
    setLibraries(libs);
  }, [test.config.libraries]);

  return (
    <Container className="main-container-padded">
      <Row>
        <Col
          xs={{ span: 12, offset: 0 }}
          md={{ span: 8, offset: 2 }}
        >
          <TestData {...test} />
          <Libraries libraries={libraries} />
        </Col>
      </Row>
    </Container>
  );
}
export default TestFinishedPage;
