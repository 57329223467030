import React, { useEffect, useState } from 'react';

import { motion } from 'framer-motion'

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { BoxLoader } from 'packages/FreeUserApp/shared/loaders/windows-loader.component';

const transition = { delay: 0.75 }
const animate = { opacity: 1 }

// Input Docs: https://react-circular-input.now.sh/examples#render-prop
const TestCreationLoader = () => (
  <motion.div style={{ opacity: 0 }} transition={transition} animate={animate}>
    <Container>
      <Row className="mb-5">
        <Col className="text-center">
          <p className="display-4">Estamos preparando tu test</p>
          <blockquote className="blockquote">
            <p className="mb-0">
              &quot;La paciencia es una virtud&ldquo;
            </p>
            <footer className="blockquote-footer">
              <em>Desconocido</em>
            </footer>
          </blockquote>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <BoxLoader />
        </Col>
      </Row>
    </Container>
  </motion.div>
  );
export default TestCreationLoader;
