import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';

import './main.scss';

// Redux
import { Provider } from 'react-redux';
import store from '../../../_core/app.store';

import 'react-circular-progressbar/dist/styles.css';

// PAGES
import AppHoc from './app.hoc';

const AppContainer = () => (
  <Provider store={store}>
    <Scrollbars
      style={{
        height: window.innerHeight,
      }}
      renderThumbVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            zIndex: 2048,
            borderRadius: '10px',
            backgroundColor: '#dfdfdf',
          }}
        />
      )}
    >
      <AppHoc />
    </Scrollbars>
  </Provider>
  )
export default AppContainer;
