import React, { useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

interface Tprops {
  total: number;
  current: number;
  className: string;
}

const TestProgressBar = ({ current, total, className }: Tprops) => {
  const progress = (current / total) * 140;

  useEffect(() => {
    current === total && setTimeout(playFinishedAnimation, 300);
  }, [current]);

  const playFinishedAnimation = () => {
    console.log('TODO: Finished animation, play something nice');
  };

  return (
    <ProgressBar
      onAnimationEnd={() => console.log('end')}
      onAnimationEndCapture={() => console.log('end capture')}
      id="progress"
      variant="success"
      className={`${className} w-100`}
      animated
      now={progress}
    />
  );
};

export default TestProgressBar;
