import * as Error from './types';

const initialState: Error.ErrorStore = null;

export default function errorReducer(
  state = initialState,
  action: Error.ActionTypes,
): Error.ErrorStore {
  switch (action.type) {
    case Error.SET_ERROR:
      console.log('[SET ERROR] ', action.payload);
      if (action.payload !== null) {
        return { ...action.payload };
      }
      return action.payload;
    default:
      return state;
  }
}
