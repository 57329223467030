import { updateOneReducer } from './reducers/update.reducer';
import * as TestHistory from './types/store-types';

const initialState: TestHistory.TestHistoryStore = {
  active: null,
  list: [],
  updated: null,
  loading: false,
};

export default function testReducer(
  state = initialState,
  action: TestHistory.ActionTypes,
): TestHistory.TestHistoryStore {
  switch (action.type) {
    case TestHistory.SET_TESTS: {
      console.log(`Test reducer => [${action.type}]`, action.payload);
      const activeTest = action.payload.find(test => test.end === undefined);
      return {
        ...state,
        list: [...action.payload],
        active: (activeTest) ? activeTest.id : state.active,
        updated: new Date(),
        loading: false,
      };
    }
    case TestHistory.ADD_TEST:
      console.log(`Test reducer => [${action.type}]`, action.payload);
      return { ...state, list: [...state.list, action.payload] };
    case TestHistory.UPDATE_TEST:
      console.log(`Test reducer => [${action.type}]`, action.payload);
      return updateOneReducer(state, action.payload);
    case TestHistory.SET_ACTIVE_TEST:
      console.log(`Test reducer => [${action.type}]`, action.payload);
      return { ...state, active: action.payload };
    case TestHistory.SET_LOADING_TESTS:
      console.log(`Test reducer => [${action.type}]`, action.payload);
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
