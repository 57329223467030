import React, { useEffect, useState } from 'react';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { AnimatePresence } from 'framer-motion';
import TTest from 'packages/_core/types/TTest';
import { Navbar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateTest } from 'packages/_core/tests/actions';

import select from 'packages/FreeUserApp/assets/sfx/select-item.wav';
import success from 'packages/FreeUserApp/assets/sfx/question-success.wav';
import fail from 'packages/FreeUserApp/assets/sfx/question-fail.wav';
import skip from 'packages/FreeUserApp/assets/sfx/question-skip.wav';

import ActionButtons from '../components/action-buttons/action-buttons.component';
import TestQuestionCard from '../components/question-card/question-card.component';
import ProgressHeader from '../components/progress-header/progress-header.component';

const PlayPractice = (test: TTest) => {
  const [contentIndex, setContentIndex] = useState(0)
  const [audio] = useState({
    click: new Audio(select),
    success: new Audio(success),
    fail: new Audio(fail),
    skip: new Audio(skip),
  })

  const dispatch = useDispatch()

  useEffect(() => {
    audio.click.load();
    audio.success.load();
    audio.fail.load();
    audio.skip.load();
  }, [])

  const registerAnswer = (id: number) => {
    audio.click.play();

    const newTest = { ...test };
    newTest.content[contentIndex].answer = {
      id,
      text: '',
      correct: false,
    };
    dispatch(updateTest({ ...newTest }));
    console.log('Selecciono respuesta', test);
  }

  const evaluateAnswer = () => {
    // Play feedback
    const answerId = test.content[contentIndex].answer?.id;
    if (test.content[contentIndex].question.answers.find((ans) =>
    ans.id === answerId && ans.correct)) {
      audio.success.play();
    } else {
      audio.fail.play();
    }

    loadNext();
    // Play feedback effects!
  };

  const loadNext = () => {
    setContentIndex(contentIndex + 1)
  };

  const skipAnswer = () => {
    // Play feedback
    audio.skip.play();
    loadNext();
  };

  // Loads a different container based on the configuration stage
  return (
    <>
      <ProgressHeader
        numberQuestions={test.content.length}
        currentQuestionKey={contentIndex}
        answer={test.content[contentIndex].answer}
        testId={test.id}
      />
      <Container>
        <Row>
          <Col sm={12} className="pt-5">
            --- PRACTICE ---
            <AnimatePresence exitBeforeEnter={false} initial={false}>
              <TestQuestionCard
                answerCallback={registerAnswer}
                direction={1}
                {...test.content[contentIndex]}
                answerId={2}
              />
            </AnimatePresence>
          </Col>
        </Row>
      </Container>

    </>
  )
}
export default PlayPractice;
