import TLibrary, { TLstats } from 'packages/_core/types/TLibrary';
import SimpleStats from 'packages/_core/types/TSimpleStats';
import { TTContent } from 'packages/_core/types/TTest';

export class Library {
    static getProgressionPercentage = (stats: TLstats) => Math.round((stats.success / (stats.fail + stats.success + stats.empty)) * 100)

    static getTestProgressionStats = (content: TTContent[]) => {
        const result: Map<number, SimpleStats> = new Map();
        content.forEach((ele) => {
            if (ele.question.library) {
                // console.log('content lib', ele.answer?.correct, ele.answer, (!ele.answer) ? 1 : 0)
                const current = result.get(ele.question.library);
                console.log('current', current)
                if (current === undefined) {
                    result.set(ele.question.library, {
                        success: (ele.answer?.correct) ? 1 : 0,
                        fail: (ele.answer?.correct === false) ? 1 : 0,
                        empty: (!ele.answer) ? 1 : 0,
                        total: 1,
                    });
                } else {
                    result.set(ele.question.library, {
                        success: (ele.answer?.correct) ? current.success + 1 : current.success,
                        fail: (ele.answer?.correct === false) ? current.fail + 1 : current.fail,
                        empty: (!ele.answer) ? current.empty + 1 : current.empty,
                        total: current.total + 1,
                    });
                }
            }
        })
        return result;
    }

    static getTestProgressionPercentage = (content: TTContent[]) => {
        const data = Library.getTestProgressionStats(content);
        const result: Map<number, number> = new Map();

        data.forEach((value, key) => {
            result.set(key, (value) ? Math.round(((value.success / value.total) * 100)) : 0)
        })
        return result;
    }
}
