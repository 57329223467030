import React, { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import cancel from 'packages/FreeUserApp/assets/sfx/cancel.wav';
import close from 'packages/FreeUserApp/assets/sfx/close.wav';

import { Redirect } from 'react-router-dom';

import { motion } from 'framer-motion';
import { TResponse } from 'packages/_core/types/TQuestion';
import TestProgressBar from '../progress-bar-default/progress-bar-default.component';

import './progress-header.scss';

const variants = {
  bar: {
    open: {
      width: '100%',
      padding: '0 15px',
    },
    closed: {
      overflow: 'hidden',
      width: 0,
      padding: '0',
    },
    success: {
      scale: [1, 1.1, 1],
    },
    fail: {
      scale: [1, 1.1, 1],
    },
    none: {},
  },
  button: {
    open: {
      rotate: [0],
      opacity: 1,
    },
    closed: {
      rotate: [0, 90, 180, 270, 360],
      opacity: [1, 0],
    },
  },
};

interface TProps {
  numberQuestions: number;
  currentQuestionKey: number;
  answer: TResponse | null | undefined;
  testId: number;
}

const ProgressHeader = ({
 numberQuestions, currentQuestionKey, answer, testId,
}: TProps) => {
  const [exit, setExit] = useState(false);
  const [finishedTest, setFinishedTest] = useState(false);
  const [answerAnimation, setAnswerAnimation] = useState('');
  const [cancelSfx] = useState(new Audio(cancel));
  const [closeSfx] = useState(new Audio(close));
  const Alert = withReactContent(Swal);

  useEffect(() => {
    if (currentQuestionKey === numberQuestions) setFinishedTest(true);
  }, [currentQuestionKey]);

  useEffect(() => {
    cancelSfx.load();
    closeSfx.load();
  }, [])

  useEffect(() => {
    if (answer) {
      if (answer.correct) {
        setAnswerAnimation('success');
      } else {
        setAnswerAnimation('fail');
      }
    } else setAnswerAnimation('none');
  }, [answer])

  const exitTest = () => {
    cancelSfx.play();
    Alert.fire({
      title: <p>¿Abandonar intento?</p>,
      html: 'Puedes continuar este test más tarde si lo deseas',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Abandonar',
      confirmButtonColor: '#cccccc',
      cancelButtonText: 'Continuar intento',
      cancelButtonColor: '#62b53c',
    }).then((result) => {
      if (result.value) {
        closeSfx.play();
        setExit(true);
      }
    });
  };

  if (exit) return <Redirect push to={`/test/${testId}`} />;

  return (
    <motion.div
      initial="open"
      animate={finishedTest ? 'closed' : 'open'}
      variants={variants.bar}
      transition={{
        duration: 0.5,
      }}
      className="container pt-md-4 pt-sm-2 pt-2 pb-md-5 pb-sm-2 pb-2"
    >
      <Row>
        <Col className="d-flex align-items-center">
          <motion.div
            initial="open"
            animate={finishedTest ? 'closed' : 'open'}
            variants={variants.button}
            transition={{
              duration: 0.3,
            }}
            onClick={exitTest}
            className="closeTestIcon"
          >
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </motion.div>
          <motion.div
            animate={answerAnimation}
            variants={variants.bar}
            transition={{
              ease: 'easeInOut',
              duration: 0.75,
            }}
          >
            <TestProgressBar
              className={`ml-3 ${answerAnimation}`}
              total={numberQuestions}
              current={currentQuestionKey}
            />
          </motion.div>
        </Col>
      </Row>
    </motion.div>
  );
};
export default ProgressHeader;
