import React, { useEffect, useState } from 'react';

import TTest, { TSHistoric, TTContent } from 'packages/_core/types/TTest';
import { Card, ProgressBar } from 'react-bootstrap';
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import TLibrary from 'packages/_core/types/TLibrary';
import { Library } from 'packages/_core/library/domain/Library';

import './library-progress.scoped.scss';

const LibraryProgressionStats = ({ libraries, content }: {
  libraries: TLibrary[],
  content: TTContent[],
}) => {
  const [testProgression, setTestProgression] = useState<Map<number, number>>(new Map())

  useEffect(() => {
      const data = Library.getTestProgressionPercentage(content);
      setTestProgression(data);
      console.log('progress data', data)
  }, [content])

  return (
    <Card.Body>
      Aciertos por librería
      <p className="font-weight-light">Tu media de aciertos general vs en este test</p>

      {
        libraries.map(({ stats, name, id }: TLibrary) => {
          console.log('lib stats', { stats, libraries })
          const totalPercent = Library.getProgressionPercentage(stats);
          const thisTestPercent = testProgression.get(id);

          return (
            <div key={`lib_progress_${id}`} className="d-flex justify-content-around mb-4">
              <div className="w-25 mr-3" style={{ maxWidth: '100px' }}>
                <CircularProgressbarWithChildren
                  value={totalPercent}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    pathColor: '#9995ff',
                    trailColor: '#f1f1f1',
                  })}
                >
                  <div className="text-center circle-text">
                    { totalPercent }
                    %
                    <br />
                    En librería
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <div className="w-75 mt-sm-3">
                <p className="mb-0">
                  { name }
                </p>
                <div className="stackedbars">
                  <ProgressBar className="w-100" label="Este test" now={thisTestPercent} striped animated variant="pink" />
                  <ProgressBar className="w-100" now={totalPercent} variant="warning" />
                </div>
                <span className="text-muted" style={{ fontSize: '0.75rem' }}>
                  { thisTestPercent }
                  % Aciertos en este test
                </span>
              </div>
            </div>
          )
        })
      }
    </Card.Body>
  );
}
export default LibraryProgressionStats;
