import { addQuestionReducer } from './reducers/add.reducer';
import { updateLibraryReducer } from './reducers/library-update.reducer';
import { deleteQuestionReducer } from './reducers/question-delete.reducer';
import { replaceQuestionReducer } from './reducers/question-replace.reducer';
import { updateQuestionReducer } from './reducers/question-update.reducer';
import * as Library from './types';

const initialState: Library.LibraryStore = {
  libraries: [],
  updated: null,
  loading: false,
};

export default function libraryReducer(
  state = initialState,
  action: Library.ActionTypes,
): Library.LibraryStore {
  switch (action.type) {
    case Library.SET_LIBRARIES:
      console.log(`Library reducer => [${action.type}]`, action.payload);
      return {
        ...state,
        libraries: [...action.payload],
        updated: new Date(),
        loading: false,
      };
    case Library.ADD_LIBRARY:
      console.log(`Library reducer => [${action.type}]`, action.payload);
      return { ...state, libraries: [...state.libraries, action.payload], updated: new Date() };
    case Library.UPDATE_LIBRARY:
      console.log(`Library reducer => [${action.type}]`, action.payload);
      return updateLibraryReducer(state, action.payload);
    case Library.ADD_QUESTION:
      console.log(`Library reducer => [${action.type}]`, action.payload);
      return addQuestionReducer(state, action.payload);
    case Library.UPDATE_QUESTION:
      console.log(`Library reducer => [${action.type}]`, action.payload);
      return updateQuestionReducer(state, action.payload);
    case Library.REPLACE_QUESTION:
      console.log(`Library reducer => [${action.type}]`, action.payload);
      return replaceQuestionReducer(state, action.payload);
    case Library.DELETE_QUESTION:
      console.log(`Library reducer => [${action.type}]`, action.payload);
      return deleteQuestionReducer(state, action.payload);
    case Library.SET_LOADING_LIBRARIES:
      console.log(`Library reducer => [${action.type}]`, action.payload);
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
