import React, { CSSProperties, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'packages/_core/app.store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';

import { motion } from 'framer-motion';
import { setError } from 'packages/_core/error/actions';
import { useLocation } from 'react-router-dom';
import NetworkConnectionError from './network-connection';

const arrowStyle: CSSProperties = {
    width: '70px',
    height: '70px',
    border: '1px solid #000000',
    borderRadius: '50%',
    fontSize: '0.3rem',
    boxShadow: '1px 2px 7px 0px #cfcfcf',
    padding: '10px',
    color: '#636363',
}

/**
 * Loads a different container based on the test status
 */
const ErrorHoc = () => {
    const state = useSelector((state: RootState) => state);
    const [rotate, setRotate] = React.useState(0);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        console.log('url', window.location.pathname, location)
        if (state.error?.route && state.error.route !== location.pathname) {
            dispatch(setError(null));
        }
    }, [location]);

    if (state.error && state.error.fatal) {
      return (
        <div className="d-flex flex-column justify-content-center h-75">
          <NetworkConnectionError />
          <motion.div
            whileTap={{ scale: 0.9 }}
            onClick={() => setRotate((prevState) => prevState + 360)}
            animate={{
                rotate,
                transition: { duration: 1, ease: 'circOut' },
            }}
            className="d-flex justify-content-center mt-5"
          >
            <FontAwesomeIcon style={arrowStyle} icon={faRedoAlt} />
          </motion.div>
        </div>
      )
    }
    return <></>;
}
export default ErrorHoc;
