import { LQuestion } from 'packages/_core/types/TQuestion';
import { AppThunk } from '../thunk.type';
import {
    replaceQuestion,
 updateLibrary, updateQuestion,
} from '../actions';
import { ResponseQuestion } from '../types/response-types';
import { Library } from '../../models/Library';

export const thunkUpdateQuestion = (question: LQuestion): AppThunk => async (
  dispatch,
  getState,
  api,
) => api.put<ResponseQuestion>(`/question/${question.id}`, question).then(
  ({ data }) => {
    if (!data.data.library) return Promise.reject();
    dispatch(replaceQuestion(data.data))
    return Promise.resolve(data.data);
  },
  (err) => err,
).catch((e) => e)
