import React from 'react';
import {
 Button, Card, Col, Row,
} from 'react-bootstrap';

import './no-questions-card.scoped.scss';
import image from '../../../../../assets/illustrations/cat-in-space.svg';

export const NoQuestionsCard = ({ createCallback }: { createCallback: () => void }) => (
  <Card className="card-bg" style={{ backgroundImage: `url(${image})` }}>
    <div className="card-body">
      <Row>
        <Col xs={12} sm={12} md={12} lg={6} className="text-right pt-5 pb-3 mobile-center" style={{ minHeight: 'calc(max(25vw, 35vh))' }}>
          <h3>Uops ¡No hay preguntas aquí!</h3>
          <Button variant="primary" className="mt-3" onClick={createCallback}>Crear la primera pregunta</Button>
        </Col>
      </Row>
    </div>
  </Card>
);
export default NoQuestionsCard;
