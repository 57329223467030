import React, { useState } from 'react';

import { Alert, Button, Card } from 'react-bootstrap';

import './create-question-action-card.scoped.scss';
import bgImg from '../../../../../assets/illustrations/to-the-moon.svg';

const CreateQuestionActionCard = (props: {createCallback: () => void}) => (
  <Card body className="text-center bg mb-4" style={{ backgroundImage: `url(${bgImg})` }}>
    <Button variant="outline-primary" onClick={props.createCallback}>Crear pregunta ahora</Button>
  </Card>
  )

export default CreateQuestionActionCard;
