import TestNotValid from 'packages/FreeUserApp/containers/test/error/test-not-valid-id';
import TLibrary, { TLstats } from '../types/TLibrary';
import { TAnswer } from '../types/TQuestion';
import TSimpleStats from '../types/TSimpleStats';
import TTest, {
    TSHistoric,
    TTConfig,
 TTContent, TType,
} from '../types/TTest';
import { TestConfig } from './TestConfig';

export class Test implements TTest {
    id: number;

    owner: number;

    start: Date | string | null;

    creation: Date;

    content: TTContent[];

    parent?: number | null | undefined;

    end?: string | Date | null | undefined;

    name?: string | undefined;

    historic?: TSHistoric[];

    config: TTConfig;

    stats: TSimpleStats;

    constructor(test?: TTest) {
        this.id = test?.id || -1;
        this.owner = test?.owner || -1;
        this.start = test?.start || null;
        this.creation = test?.creation || new Date();
        this.content = test?.content || [];
        this.stats = test?.stats || {
            success: 0, fail: 0, empty: 0, total: 0,
        };

        this.config = new TestConfig(test?.config);
    }

    private static findContent(list: TTContent[], id: number) {
        let index = -1;
        const content = list.find((ele, idx) => {
            if (ele.id === id) {
                index = idx;
                return true;
            }
            return false;
        });
        if (!content) throw new Error('Content id not found for this test');
        return {
            content,
            index,
        };
    }

    private static findAnswer(content: TTContent, id: number) {
        const answer = content.question.answers.find((ans) =>
            ans.id === id);

        if (!answer) throw new Error('Answer not found for this content')
        return answer;
    }

    /**
     * Update test data to fit newly answered content
     * @param test test data
     * @param contentId contentId to add answer to
     * @param answerId answer id to set as user selection
     */
    static setAnswer = (test: TTest, contentId: number, answerId: number | null) => {
        // Find content and answer data
        const { content, index } = Test.findContent(test.content, contentId);

        // Remove old stats if content existed. Used to keep them up to date on test retries
        if (content.answer === null) {
            test.stats.empty -= 1;
        } else if (content.answer && content.answer.correct === true) {
            test.stats.success -= 1;
        } else if (content.answer && content.answer.correct === false) {
            test.stats.fail -= 1;
        }

        // Add the answer to the content answer
        if (answerId === null) {
            test.content[index].answer = null;
            test.stats.empty += 1;
        } else {
            const answer = Test.findAnswer(content, answerId)
            test.content[index].answer = {
                id: answer.id,
                text: answer.text,
                correct: answer.correct,
            };

            // Increase the fail/success/emtpy counter
            if (answer.correct) {
                test.stats.success += 1;
            } else {
                test.stats.fail += 1;
            }
        }

        if (test.config.type !== TType.test) test.stats.total += 1;

        // Update lib data for this test
        test.config.libraries = Test.updateTestLibraryStats(test, test.content[index], (content.answer === undefined) ? null : content);

        return test;
    }

    /**
     * Modify a test's library stats based on a content answer
     * @param test Test to modify data on
     * @param contentNew Content to update stats based on
     * @param contentOld if provided, removes the stats from previous answers. Usefull when user retries a test.
     * @param result Type of answer result (success/fail/empty)
     * @returns TLibrary[] with the updated stats acording to content and result provided
     */
    private static updateTestLibraryStats(test: TTest, contentNew: TTContent, contentOld: TTContent | null) {
        const libs = test.config.libraries as TLibrary[];
        const libIndx = libs.findIndex(lib => lib.id === contentNew.question.library);
        if (libIndx > -1) {
            const lib = test.config.libraries[libIndx] as TLibrary;
            if (contentOld !== null) { // Remove old stats if provided
                if (contentOld.answer === null) {
                    lib.stats.empty -= 1;
                } else if (contentOld.answer?.correct === true) {
                    lib.stats.success -= 1;
                } else {
                    lib.stats.fail -= 1;
                }
            }

            // Add new stats
            if (contentNew.answer === null) {
                lib.stats.empty += 1;
            } else if (contentNew.answer?.correct === true) {
                lib.stats.success += 1;
            } else {
                lib.stats.fail += 1;
            }
            test.config.libraries[libIndx] = lib;
        }

        return test.config.libraries;
    }

    /**
     * Resets the failed or skipped questions so the user can retry them
     * @param test test data
     */
    static resetFailedAnswers = (test: TTest, includeEmpty: boolean = false) => {
        const historic = test.historic?.slice(-1);
        if (historic && historic[0]?.testId === test.id) test.historic?.pop();

        test.content.forEach((ele) => {
            if (ele.answer?.correct === false) {
                test.stats.fail -= 1;
                ele.answer = undefined
            } else if (includeEmpty && !ele.answer) {
                test.stats.empty -= 1;
            }
        })
        return test;
    }

    /**
     * Returns the content and index of the next available content.
     * @param test test data
     * @returns index -1 if no more content exists
     * @return content null if no more content available
     */
    static getNextContent = (test: TTest) => {
        const idx = test.content.findIndex(cnt => cnt.answer === undefined);
        const content = (idx > -1) ? test.content[idx] : null;
        return {
            content,
            index: idx,
        };
    }

    /**
     * Returns true if the user can repeat this test (because has failed or skipped questions)
     * @param test test
     */
    static canRepeatTest = (test: TTest, includeEmpty: boolean = false) => {
        let canRepeat = false;
        test.content.forEach((ele) => {
            if ((!ele.answer && includeEmpty) || ele.answer?.correct === false) canRepeat = true;
        })
        return canRepeat;
    }

    static addHistoryData = (test: TTest, newStats: TSimpleStats) => {
        const hist = test.historic || [];
        const currentHistIndx = hist.findIndex((ele) => ele.testId === test.id);

        const newHistData = {
            success: newStats.success,
            fail: newStats.fail,
            empty: newStats.empty,
            total: newStats.fail + newStats.success + newStats.empty,
            testId: test.id,
            date: test.creation,
        };

        if (currentHistIndx >= 0) {
            hist[currentHistIndx] = newHistData;
        } else {
            hist.push(newHistData)
        }

        const newTest = {
            ...test,
            historic: hist,
        }
        return newTest;
    }
/*
    getHistoric = (): TSHistoric => ({
        fail: this.fail,
        success: this.success,
        empty: this.empty,
        id: this.id,
        start: this.start,
    })

    private getLibraryStats = (libraryId: number) => {
        const contentStats: TLstats = {
            questions: 0,
            fail: 0,
            success: 0,
            empty: 0,
        };

        this.content.forEach((cnt) => {
            console.log('libs ids', cnt.library, libraryId)
            if (cnt.question.library?.id === libraryId) {
                if (!cnt.answer) contentStats.empty += 1;
                if (cnt.answer?.correct) contentStats.success += 1;
                if (cnt.answer?.correct === false) contentStats.fail += 1;
                contentStats.questions += 1; // increase total question
            }
        })
        return contentStats;
    }

    getProgresion = (): TLstats[] => {
        const progression: TLstats[] = [];

        this.libraries.forEach((ele) => {
            progression.push({
                fail: ele.fail,
                success: ele.success,
                empty: ele.empty,
            });
        })
        return progression;
    }
    */

    public static findById = (list: TTest[], id: number) => list.find(test => test.id === id)
}
