import React, { useEffect, useState } from 'react';

import {
  LabelList,
  Line, LineChart, ResponsiveContainer,
} from 'recharts';
import TTest, { TSHistoric } from 'packages/_core/types/TTest';
import { Card } from 'react-bootstrap';

import './test-historic-stats.scoped.scss';

const tmp = [
  {
    ratio: 50,
  },
  {
    ratio: 30,
  },
]

const TestHistoricStats = (test: TTest) => {
  const [data, setData] = useState<{ ratio: number }[]>([])

  useEffect(() => {
    processData();
  }, [test])

  const processData = () => {
    const newData: { ratio: number }[] = [];
    test.historic?.forEach((hist) => {
      const ratio = (hist.success / (hist.fail + hist.empty + hist.success));
      if (Number(ratio) && Number.isFinite(ratio)) {
        newData.push({ ratio: ratio * 100 });
      }
    });
    if (newData.length === 1) newData.push(newData[0]) // TODO: Show something nicer for newcomers
    setData([...newData]);
  }

  return (
    <Card.Body>
      Progreso en test similares
      <p className="font-weight-light">Haz más tests como este para ver tu progresión aquí</p>
      <div className="chart-container">
        <ResponsiveContainer aspect={4}>
          <LineChart
            data={data}
            margin={{
              top: 20, right: 10, left: 5, bottom: 5,
            }}
          >
            <Line
              isAnimationActive={false}
              animationBegin={500}
              label={{ fill: '#8884d8', fontSize: 14, dy: -14 }}
              type="monotone"
              dataKey="ratio"
              stroke="#8884d8"
              strokeWidth={2}
              dot
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card.Body>
  );
}
export default TestHistoricStats;
