import { LQuestion, TAnswer } from '../types/TQuestion';

export class Question {
    public static create = (props: { id?: number, text?: string, parent?: LQuestion, library: number, answers?: TAnswer[] }): LQuestion => ({
            id: props.id || -1,
            creator: -1,
            parent: props.parent,
            text: props.text || '',
            answers: props.answers || [],
            library: props.library,
            creation: new Date(),
            stats: {
                success: 0,
                fail: 0,
                empty: 0,
                total: 0,
            },
        })

    public static removeEmptyAnswers = (question: LQuestion) => {
        const filledAnswers = question.answers.filter(ele => {
            ele.text.replace(/\s/g, '')
            if (ele.text.length !== 0) return true;
            return false;
        })
        return {
            ...question,
            answers: filledAnswers,
        };
    }
}
