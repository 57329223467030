import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from 'packages/_core/app.store';
import React from 'react';

import { useSelector } from 'react-redux';
import CardBaseComponent from './components/card-base/card-base.component';
import CardBodyOneColComponent from './components/card-body-one-col/card-body-one-col.component';

export const UserFeedContainer = () => {
  const { libraries, tests } = useSelector((state: RootState) => state)
    return (
      <>
        <CardBaseComponent
          disabled={(libraries.libraries.length > 0 && !libraries.loading)}
          icon={
            (libraries.libraries.length > 0 && !libraries.loading)
            ? <FontAwesomeIcon icon={faCheck} /> : <span>1</span>
          }
          link="/librerias/nueva"
        >
          <CardBodyOneColComponent title="Crea una librería" desc="Son la forma de organizar tus preguntas para elegirlas en los test" />
        </CardBaseComponent>

        <CardBaseComponent
          disabled={(libraries.libraries.length === 0 || libraries.loading || tests.list.length > 0 || tests.loading)}
          type="action"
          icon={
            (tests.list.length > 0 && !tests.loading)
            ? <FontAwesomeIcon icon={faCheck} /> : <span>2</span>
          }
          link="/test/nuevo"
        >
          <CardBodyOneColComponent title="Practica con un test" desc="Mejora día a día con los test específicos" />
        </CardBaseComponent>

        <CardBaseComponent
          disabled={(tests.list.length === 0 || tests.loading)}
          type="alert"
          icon={
            (tests.list.length > 0 && !tests.loading)
            ? <FontAwesomeIcon icon={faCheck} /> : <span>3</span>
          }
          link="/resultados"
        >
          <CardBodyOneColComponent title="Sigue practicando" desc="Verás como mejoras en aciertos, cantidad de conocimiento y temarios" />
        </CardBaseComponent>
      </>
    )
}

export default UserFeedContainer;
