import React from 'react';
import TTest from 'packages/_core/types/TTest';

import Moment from 'react-moment';

import { Card } from 'react-bootstrap';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import ValorationText from '../../service/test-valoration';

import './test-data.scoped.scss'
import 'moment/locale/es';

const style = {
  marginBottom: '15px',
}

const TestData = (test: TTest) => {
  const value = (test.stats.success > 0) ? ((test.stats.fail + test.stats.success) / test.stats.success) * 100 : 0

  return (
    <Card style={style} body className="text-center">
      <Card.Body>
        <Card.Title>
          {ValorationText(test)}
        </Card.Title>
        <div style={{ width: '100%', maxWidth: '30%', margin: '0 auto' }}>
          <CircularProgressbarWithChildren
            backgroundPadding={6}
            value={value}
            styles={buildStyles({
              trailColor: '#f3f3f3',
            })}
          >
            <div style={{ marginTop: -5 }}>
              {test.stats.success}
              /
              {test.content.length}
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <p className="finished-time">
          Terminado
          <br />
          <Moment
            locale="es"
            date={test.end as string}
            fromNow
          />
        </p>
      </Card.Body>
    </Card>
  )
}
export default TestData;
