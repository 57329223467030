import React, { useEffect, useState } from 'react';

import { RootState } from 'packages/_core/app.store';
import { useSelector, useDispatch } from 'react-redux';

import { RouteComponentProps, useParams } from 'react-router-dom';
import { thunkRetrieveTest } from 'packages/_core/tests/thunks/tests.thunk';
import TTest from 'packages/_core/types/TTest';
import { findTestInList } from 'packages/_core/tests/helper.fn';

import { TestDispatch } from 'packages/_core/tests/thunk.type';
import { Loader } from 'packages/FreeUserApp/shared/loaders/windows-loader.component';
import TestFinishedResume from './finished/finished.page';
import TestNotValid from '../error/test-not-valid-id';
import TestUnfinishedResume from './unfinished/unfinished.page';
import LoadingPage from '../../loading/loading.page';

/**
 * Loads a different container based on the test status
 */
const TestDetail = () => {
  const dispatch: TestDispatch = useDispatch();
  const [test, setTest] = useState<TTest | null>(null);
  const [error, setError] = useState(false);

  const { tests } = useSelector((state: RootState) => state);
  const { id } = useParams<{ id: string }>();
  const urlId = Number(id);

  const getTestData = () => {
    const tmpTest = findTestInList(tests.list, urlId);
    if (tmpTest) {
      setTest(tmpTest);
    } else {
      dispatch(thunkRetrieveTest(urlId))
      .catch((e) => setError(true))
    }
  }

  useEffect(() => {
    getTestData();
  }, [])

  useEffect(() => {
    if (urlId < 0) return;
    getTestData()
  }, [tests, id]);

  // If url id !== test.id, call server to gather data (if not present in store loaded tests)
  // If test is finished, show results page
  // If test is NOT finished, show continue page or directly progression page...

  if (urlId >= 0 && !error) {
    if (!test) return <LoadingPage />
    if (test.id === urlId) {
      if (test.end) return <TestFinishedResume {...test} /> // Test is finished
      if (test.start) return <TestUnfinishedResume {...test} /> // Test started but not finished
      return <TestUnfinishedResume {...test} /> // Test was not even started...
    }
  }
  return <TestNotValid />
}
export default TestDetail;
