import React from 'react';
import {
 Button, Card, Col, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './no-questions-found-card.scoped.scss';
import image from '../../../../../assets/illustrations/cat.svg';

export const NoQuestionsFoundCard = ({ search, createCallback }: { search: string, createCallback: () => void }) => (
  <Card body className="card-bg" style={{ backgroundImage: `url(${image})` }}>
    <Row>
      <Col xs={12} sm={12} md={6} className="text-right pt-5 pb-3 mobile-center" style={{ minHeight: 'calc(max(25vw, 35vh))' }}>
        <h3>
          No hay nada para &quot;
          {search}
          &quot;
        </h3>

        <Button variant="primary" className="mt-3" onClick={createCallback}>Crear la pregunta perfecta</Button>
      </Col>
    </Row>
  </Card>
);
export default NoQuestionsFoundCard;
